import LoginPage from './app/pages/auth/Login';
import RegisterPage from './app/pages/auth/RegisterPage';
import BetGuide from './app/pages/bet-guide/page';
import Dashboard from './app/pages/dashboard';
import HomePage from './app/pages/default/Homepage';
import LiveBetting from './app/pages/livebetting/page';


const routes = [
  {
    path: '/login',
    element: LoginPage,
    isProtected: false,
  },
  {
    path: '/register',
    element: RegisterPage,
    isProtected: false,
  },
  {
    path: '/dashboard',
    element: Dashboard,
    isProtected: false,
  },
  {
    path: '*',
    element: HomePage,
    isProtected: false,
  },
  {
    path:'/live-betting',
    element:LiveBetting,
    isProtected:false
  }
  ,  {
    path:'/bet-guide',
    element:BetGuide,
    isProtected:false
  }
 
];

export default routes;
