import DashboardSlider from "./MainPageComponents/top_combos_section";
import { TopEvents } from "./Top_Evetns";
import { LastminEvents } from "./last_min";






export function MainPage(){
    return <>
    <DashboardSlider isMarketSectionVisible={false}/>
    <TopEvents inMain={true}/>
    <LastminEvents inMain={true}/>
    </>
}