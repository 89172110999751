import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
  applepay,
  bayern,
  bbl,
  bundesliga,
  bundesliga2,
  dsww,
  eighteenplus,
  footerlogo,
  master,
  neteller,
  paypal,
  paysafecard,
  skrill,
  skrilltrap,
  sofort,
  therapy,
  trustly,
  tuv,
  visa,
} from "../../assets/images";

const Footer = () => {
  const getCurrentDateAndTime = () => {
    const formattedDate = moment().format("DD.MM.YYYY HH:mm");
    return formattedDate;
  };

  return (
    <footer className="hidden md:block">
      <div className="flex flex-col items-center w-full py-5 px-16">
        <div className="flex justify-between w-full">
          <div>
            <h6 className="font-light pb-3">Information</h6>
            <ul className="list-none font-light text-sm text-gray-500">
              <li>
                <Link to="/">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Player Protection</Link>
              </li>
              <li>
                <Link to="/">Sports Blog</Link>
              </li>
              <li>
                <Link to="/">Terms Of Promotional Offer</Link>
              </li>
              <li>
                <Link to="/">Cookie Settings</Link>
              </li>
              <li>
                <Link to="/">Responsible Gambling</Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-light pb-3">The Company</h6>
            <ul className="list-none font-light text-sm text-gray-500">
              <li>
                <Link to="/">Who is Tipico?</Link>
              </li>
              <li>
                <Link to="/">Careers</Link>
              </li>
              <li>
                <Link to="/">Legal Notice</Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 className=" font-light pb-3">Contact</h6>
            <ul className="list-none font-light text-sm text-gray-500">
              <li>
                <Link to="/">Help Center</Link>
              </li>
              <li>
                <Link to="/">Tipico Group</Link>
              </li>
              <li>
                <Link to="/">Franchise Program</Link>
              </li>
              <li>
                <Link to="/">Affiliate Program</Link>
              </li>
            </ul>
          </div>
          <div className="pr-24">
            <p className=" font-light text-sm text-gray-500 pb-3">
              {getCurrentDateAndTime()}
            </p>
          </div>
        </div>

        <hr className="bg-black w-full my-5 h-[1.5px]" />

        <div className="flex justify-between w-full pr-12">
          <div className="">
            <h6>Trust and Security</h6>
            <ul className="list-none flex items-center  flex-wrap">
              <li>
                <Link to="/">
                  <img src={eighteenplus} alt="logo" width={55} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={tuv} alt="logo" width={55} height={35}/>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={therapy} alt="logo" width={55} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={dsww} alt="logo" width={55} height={35}/>
                </Link>
              </li>
            </ul>
          </div>
          <div className="">
            <h6>Our Partners</h6>
            <ul className="list-none flex items-center  flex-wrap">
              <li>
                <Link to="/">
                  <img src={bayern} alt="logo" width={55} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={bundesliga} alt="logo" width={55} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={bundesliga2} alt="logo" width={55} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={bbl} alt="logo" width={55} height={35} />
                </Link>
              </li>
            </ul>
          </div>
          <div className="">
            <h6>Payment Methods</h6>
            <ul className="list-none flex items-center mt-2 flex-wrap">
              <li>
                <Link to="/">
                  <img src={paypal} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={sofort} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={paysafecard} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={applepay} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={trustly} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={skrill} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={skrilltrap} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={neteller} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={master} alt="logo" width={54} height={35} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={visa} alt="logo" width={54} height={35} />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <hr className="bg-black w-full my-5 h-[1.5px]" />

        <div className="">
          <Link to="/" className="footer-logo">
            <img src={footerlogo} alt="companylogo" width={154} height={68} />
          </Link>
          <p>
            18+. Gambling can be addicting. Play responsibly. You can find more
            information on our player protection page. Advice and help for those
            affected and their relatives is available at{" "}
            <Link href="www.bundesweit-gegen-gluecksspielsucht.de">
              www.bundesweit-gegen-gluecksspielsucht.de
            </Link>{" "}
            and <Link href="www.check-dein-spiel.de">www.check-dein-spiel.de</Link>.
          </p>
          <p>
            Tipico Co Ltd was granted official permission to organise sports
            betting on the internet as well as stationary on December 9th, 2022
            ('officially authorized sportsbetting operator'). Tipico Co Ltd
            operates under the supervision of the GGL. Tipico Co Ltd's
            registered address is Tipico Tower, Vjal Portomaso, STJ 4011 St.
            Julian's, Malta.
          </p>
          <p>
            Online sport bets - Live bets like never before – no matter whether
            you are a fan of football, tennis, basketball or ice hockey or
            whether you are interested in particular in the German Bundesliga,
            Spanish Primera Division, English Premier League, Italian Serie A,
            or the 2020 football European Championship, you will always be in on
            the action with Tipico bets. We offer above-average betting odds, a
            clear live score and results service for everything from football to
            volleyball, a comprehensive live bet offer and much more. Register a
            betting account now and secure your deposit bonus of up to 100 €.
          </p>
        </div>

        <div
          id="footer-scroll-top"
          className="bg-[var(--grey-pale)] text-base leading-[48px] text-center cursor-pointer"
        >
          <span className="align-middle">Back to top</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
