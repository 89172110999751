// src/layouts/MainLayout.js
import React from 'react';
import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import { RightSidebar } from '../../components/Sidebar/RightSidebar';
import { BottomAppbar } from '../../components/BottomAppbar';




const LiveBattingLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <Header />
      <div className="flex flex-grow md:mt-14">
        <main className="flex-1 overflow-x-auto ">
        {children}
        </main>
        <RightSidebar/>
      </div>
        <BottomAppbar/>
    </div>
  );
};

export default LiveBattingLayout;
