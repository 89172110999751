const initialState = {
  isAuthenticated: false,
  data: null,
  error: null,
};

export const LiveMatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LIVEMATCHES_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload,
        error: null,
      };
    case "LIVEMATCHES_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };
    case "LIVEMATCHES_UPDATE":
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload,
        error: null,
      };
    case "LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1":
      if (action.payload.data.length === 0) {
        return {
          ...state,
          isAuthenticated: false,
          data: state.data,
          error: "No odds data found",
        };
      }
      const websocketDatas = action.payload.data;
      const updatedOdds = { ...state.data.odds };
      const stateData = { ...state.data };

      websocketDatas.forEach((webOdd) => {
        const id = `${webOdd.match_id}_${webOdd.market_id}`;
        if (updatedOdds[id]) {
          const existingOddIndex = updatedOdds[id].findIndex(
            (odd) => odd.name === webOdd.name && odd.line === webOdd.line
          );
          if (id.startsWith("12763078_5")) {
            console.log("kadir");
          }
          if (existingOddIndex !== -1) {
            updatedOdds[id][existingOddIndex] = webOdd;
          }
        }
      });
      stateData.odds = updatedOdds;
      if (state.data !== stateData) {
        console.log("state.data", state.data);
        console.log("stateData", stateData);
      }
      return {
        ...state,
        isAuthenticated: true,
        data: stateData,
        error: null,
      };

    default:
      return state;
  }
};
