import { useState } from "react";
import { bet_guide, carccashout, cashout, favorite,  } from "../../../assets/images";


const list= [
    {
      name:'Bet Guide',
      image:bet_guide
    },
    {
      name:'Car ccashout',
      image:carccashout
    }, {
      name:'Favorite',
      image:favorite
    }, {
      name:'Cashout',
      image:cashout
    },
  ];

export default function BetGuideSidebar(){
    const [activeTab, setActiveTab] = useState("Bet Guide");
    return    <div className={`hidden sm:block w-1/3 lg:w-1/4 h-[95vh]  overflow-auto p-3 shadow-sm bg-white scrollbar-hide`}>
     
     <ul className="space-y-2">
          {list.map((row) => (
            <li
              className={`flex items-center cursor-pointer p-3 rounded hover:bg-gray-200
                ${
                    activeTab === row.name ? 'bg-gray-200 ' : ''
              }`}
              onClick={() => setActiveTab(row.name)}
              key={row.name}
            >
              <img className="mr-3" src={row.image} alt="icon" width={45} height={45} />
              <div className="flex flex-col">
              <span className="text-sm font-semibold">{row.name}</span>
                      <span className="text-xs text-gray-500">Learn the basics of sports betting in just a few minutes.</span>
              </div>
            </li>
          ))}
        </ul>
    </div>
}