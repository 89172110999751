export const OddsNameGroup = ({ selectedOddsIds, availableOdds }) => {
  return (
    <div className="sticky grid grid-cols-8 lg:grid-cols-12 top-[60px] bg-gray-100 border-b border-gray-300 pt-5 pb-1 px-3 z-10">
      <div className="col-span-5"></div>
      <div className="col-span-7 grid grid-cols-6">
        <div className="col-span-11 flex justify-between px-6 font-semibold text-xs text-gray-40">
          <div className="col-span-10 flex gap-1 w-full justify-between">
            <div className="flex flex-row gap-10">
              {selectedOddsIds.map((oddId, index) => {
                const captions = availableOdds[oddId]?.captions || [];
                return (
                  <div
                    key={index}
                    className="flex justify-center items-center grid-cols-1 gap-2"
                  >
                    {captions.map((caption, idx) => (
                      <span
                        key={idx}
                        className="flex justify-center items-center rounded-md py-2 cursor-not-allowed w-8"
                      >
                        {caption}
                      </span>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
