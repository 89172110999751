import { Option, Select } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/joy/IconButton";
import CloseRounded from "@mui/icons-material/CloseRounded";

const ComboboxGroup = ({ options, count, hasEmptyLabel, onValuesChange }) => {
  const initialSelectedValues =
    options.length > 0
      ? options.slice(0, count).map((opt) => opt.value)
      : Array(count).fill("");

  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

  const handleSelectChange = (index, selectedValue) => {
    const newValues = [...selectedValues];
    newValues[index] = selectedValue;
    setSelectedValues(newValues);
  };

  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(selectedValues);
    }
  }, [selectedValues]);

  const handleClearSelect = (index) => {
    const newValues = [...selectedValues];
    newValues[index] = "";
    setSelectedValues(newValues);
  };

  const renderOptions = (selectedValues, currentSelectValue) => {
    return options.map((option) => (
      <Option
        key={option.value}
        value={option.value}
        disabled={
          option.value !== "" &&
          selectedValues.includes(option.value) &&
          option.value !== currentSelectValue
        }
      >
        {option.label}
      </Option>
    ));
  };

  return (
    <div className="flex gap-10">
      {selectedValues.map((value, index) => (
        <div key={index} className="relative flex items-center">
          <Select
            className="bg-white h-9 w-36 rounded-md shadow-none hover:border-black focus:outline-none pr-6"
            placeholder="Select"
            value={value}
            {...(hasEmptyLabel &&
              value && {
                endDecorator: (
                  <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    className="absolute right-0 mr-2"
                    onClick={() => {
                      handleClearSelect(index);
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                ),
                indicator: null,
              })}
            onChange={(e, newValue) => handleSelectChange(index, newValue)}
          >
            {renderOptions(selectedValues, value)}
          </Select>
        </div>
      ))}
    </div>
  );
};

export default ComboboxGroup;
