import axios from "axios";
import { MATCHES_SUCCESS, MATCHES_FAILURE } from "./actiontypes";
import { MatchData_URL } from "../paths";


export const MatchesApi = (credentials) => async (dispatch) => {



  try {
    // if (token) {
   
      const response = await axios.post(MatchData_URL, credentials);
      dispatch({
        type: MATCHES_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    // } else {
      // dispatch({
      //   type: MATCHES_FAILURE,
      //   payload: "api failed",
      // });
    // }
  } catch (error) {
    dispatch({
      type: MATCHES_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};


