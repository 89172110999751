export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const MATCHES_SUCCESS = "MATCHES_SUCCESS";
export const MATCHES_FAILURE = "MATCHES_FAILURE";

export const SPORTS_SUCCESS = "SPORTS_SUCCESS";
export const SPORTS_FAILURE = "SPORTS_FAILURE";

export const TOPMATCHES_SUCCESS = "TOPMATCHES_SUCCESS";
export const TOPMATCHES_FAILURE = "TOPMATCHES_FAILURE";

export const TOPWINNER_SUCCESS = "TOPWINNER_SUCCESS";
export const TOPWINNER_FAILURE = "TOPWINNER_FAILURE";

export const LIVEMATCHES_SUCCESS = "LIVEMATCHES_SUCCESS";
export const LIVEMATCHES_FAILURE = "LIVEMATCHES_FAILURE";
