import { useEffect, useState } from "react";
import {
  bet_guide,
  carccashout,
  cashout,
  favorite,
  nodata,
} from "../../assets/images";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Button } from "../Button/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearOdds, removeOdd } from "../../store/actions/homeTypes";
import { TopWinnerApi } from "../../store/actions/topWinners";
const list = [
  {
    name: "Bet Guide",
    image: bet_guide,
  },
  {
    name: "Car ccashout",
    image: carccashout,
  },
  {
    name: "Favorite",
    image: favorite,
  },
  {
    name: "Cashout",
    image: cashout,
  },
];
export function RightSidebar() {
  const [betTab, setBet] = useState("Bet Slip");
  const [winnerTab, setWinnerTab] = useState(0);
  const [winner, setWinner] = useState([]);
  const [totalRate, setTotalRate] = useState(0);
  const oddsList = useSelector((state) => state.homereducer.odds);
  const dispatch = useDispatch();
  const allWinner = useSelector((state) => state.topWinner);
  // console.log(oddsList);
  useEffect(() => {
    const total = oddsList.reduce((acc, bet) => acc * bet.value, 1);
    if (total) {
      setTotalRate(total.toFixed(2));
    }
  }, [oddsList]);
  useEffect(() => {
    dispatch(TopWinnerApi());
    changeWinnerTab(0);
  }, [dispatch]);

  const changeWinnerTab = (winnerTab) => {
    setWinnerTab(winnerTab);
    setWinner(allWinner?.data?.data[winnerTab]);
  };

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
  });
  return (
    <>
      <div className="hidden md:block md:w-1/4 lg:w-1/4 h-[95vh]  overflow-auto px-3 py-6 shadow-sm bg-gray-200 ">
        <div className="w-full ">
          {/* main tabs  */}

          <div className="flex items-center w-full h-16 bg-white">
            <div
              className={`flex items-center cursor-pointer justify-center w-1/2 h-full  border-b-2 ${
                betTab === "Bet Slip"
                  ? "border-b-2  border-red-500"
                  : "border-b-2  border-gray-50"
              } text-center`}
              onClick={() => {
                setBet("Bet Slip");
              }}
            >
              <div className="flex flex-col">
                <span className="text-xl font-semibold text-gray-600">
                  {oddsList.length}
                </span>
                <span className="font-semibold text-xs">Bet Slip</span>
              </div>
            </div>
            <div
              className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
                betTab === "Open Bets"
                  ? "border-b-2  border-red-500"
                  : "border-b-2  border-gray-50"
              } `}
              onClick={() => {
                setBet("Open Bets");
              }}
            >
              <div className="flex flex-col">
                <span className="text-xl font-semibold text-gray-600">0 0</span>
                <span className="font-semibold text-xs"> Open Bets</span>
              </div>
            </div>
          </div>

          {/* tabs div  */}
          <div className="flex  justify-between  w-full  bg-white">
            {oddsList.length === 0 ? (
              <>
                <div className="flex flex-col gap-y-3 w-full  h-48 justify-center items-center">
                  <img src={nodata} alt={nodata} width={50} height={110} />

                  <p className="text-sm">
                    <span>
                      Dein Wettschein ist noch leer.
                      <br />
                      Wähle jetzt deine Wetten aus.
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="bg-white flex flex-col  w-full items-start justify-start border border-gray-300 rounded-sm shadow-sm">
                  <div className="flex justify-between w-full items-center mt-2 px-2 pb-1">
                    <div>
                      <Controller
                        name="betone"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              { value: "Tip", label: "Tip" },
                              { value: "About/Under", label: "About/Under" },
                            ]}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#fff",

                                width: "130px",
                                borderRadius: "5px",
                                borderColor: "#757575",
                                color: "#757575",
                                boxShadow: "none",
                                "&:hover": {
                                  borderColor: "#757575",
                                },
                              }),
                              indicatorSeparator: () => ({ display: "none" }),
                            }}
                          />
                        )}
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        dispatch(clearOdds());
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#6b7280"
                      >
                        <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                      </svg>
                    </div>
                  </div>
                  {/* all bets  detaail */}
                  {oddsList.map((bet, index) => {
                    return (
                      <div className="w-full" key={index}>
                        <div className="flex bg-white w-full px-2 py-2 border-b border-gray-200">
                          <div className="flex bg-white w-full justify-between items-center">
                            <div className="grid grid-cols-1">
                              <span>
                                {bet.home_team_name} : {bet.away_team_name}
                              </span>
                              {/* <span className="text-sm text-gray-400">
                                {bet.away_team_name}
                              </span> */}
                            </div>
                            <div className="flex gap-1 items-center">
                              <span>{bet.value}</span>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  dispatch(removeOdd(bet));
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="20px"
                                  viewBox="0 -960 960 960"
                                  width="20px"
                                  fill="#D9D9D9"
                                >
                                  <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* total bets  */}
                  <div className="flex bg-white w-full px-2 py-3 border-b border-gray-200">
                    <div className="flex bg-white w-full justify-between">
                      <span>Number of bets</span>
                      <span>{oddsList.length.toString()}</span>
                    </div>
                  </div>
                  {/* rate of bets  */}
                  <div className="flex justify-between w-full gap-2 px-2 mt-2">
                    <div className="bg-white w-full p-2">
                      <div className="flex justify-start">
                        <span className="">{totalRate}x</span>
                        <span className="font-bold">€4</span>
                      </div>

                      <div className="flex justify-start">
                        <span className="">Bet per bet</span>
                      </div>
                    </div>
                    {/* <div className="bg-white w-full p-2">
                      <div className="flex justify-start">
                        <span className="font-bold">€{totalRate * 4}</span>
                      </div>

                      <div className="flex justify-start">
                        <span className="">Bet per bet</span>
                      </div>
                    </div> */}
                  </div>
                  {/* price  */}

                  <div className="flex w-full flex-col justify-end items-end py-2 px-4">
                    <span className="font-semibold text-green-700">
                      {totalRate * 4} €
                    </span>
                    <span className="font-normal text-gray-500 text-sm">
                      {" "}
                      Possible Profit
                    </span>
                  </div>
                  {/* Button  */}
                  <div className="px-2 w-full py-2 ">
                    <Button className="bg-green-700 text-white rounded-md h-12 w-full">
                      Bet leaving
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* // second section  */}

        <div className="w-full ">
          {/* main tabs  */}
          <div className="flex justify-start py-4 px-4 font-semibold">
            TOP WINNERS
          </div>
          <div className="flex items-center w-full h-12 bg-white">
            <div
              className={`flex items-center cursor-pointer justify-center w-1/2 h-full  border-b-2 ${
                winnerTab === 0
                  ? "border-b-2  border-red-500"
                  : "border-b-2  border-gray-50"
              } text-center`}
              onClick={() => {
                changeWinnerTab(0);
              }}
            >
              <div className="flex ">
                <span className="font-semibold text-xs">1 day</span>
              </div>
            </div>
            <div
              className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
                winnerTab === 1
                  ? "border-b-2  border-red-500"
                  : "border-b-2  border-gray-50"
              } `}
              onClick={() => {
                changeWinnerTab(1);
              }}
            >
              <div className="flex">
                <span className="font-semibold text-xs"> 1 week</span>
              </div>
            </div>
            <div
              className={`flex items-center cursor-pointer justify-center w-1/2 h-full text-center ${
                winnerTab === 2
                  ? "border-b-2  border-red-500"
                  : "border-b-2  border-gray-50"
              } `}
              onClick={() => {
                changeWinnerTab(2);
              }}
            >
              <div className="flex ">
                <span className="font-semibold text-xs">1 month</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center  px-4 w-full h-12 bg-gray-100 text-gray-400">
            <span className="pl-4 ">Win</span>
            <span>Use</span>
          </div>
          {/* tabs div  */}
          <div className="flex flex-col  justify-between  w-full  bg-white">
            {Array.isArray(winner) && winner.length > 0 ? (
              winner.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col  w-full justify-center items-center "
                  >
                    <Accordion
                      slotProps={{ heading: { component: "h4" } }}
                      className=""
                    >
                      <AccordionSummary
                        expandIcon={
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="#e8eaed"
                            >
                              <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                            </svg>
                          </>
                        }
                        aria-controls=""
                        id="panel1-header"
                        className="hover:bg-gray-300 "
                      >
                        <div className="flex justify-between w-full items-center">
                          <div className="flex gap-3">
                            <span className="text-gray-400">{index + 1}.</span>
                            <span className="font-semibold text-sm">
                              {data.totalPayout.toFixed(2)}{" "}
                              {data.originCurrency}
                            </span>
                          </div>

                          <span>
                            {data.originCurStake.toFixed(2)}{" "}
                            {data.originCurrency}
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="bg-gray-200">
                        <div className="flex  ">
                          <div className="flex flex-col">
                            {[1, 2, 3, 4, 5].map((index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col pl-3 border-b py-1 border-b-gray-200"
                                >
                                  <span className="text-sm font-semibold">
                                    Rephaaol Gerrerio meets and Bayern Munich
                                    winds : Yes
                                  </span>
                                  <span className="text-xs font-extralight text-gray-500">
                                    Bayern Munich - Dinamo Zagreb
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>

        {/* third section  */}

        <div className="w-full ">
          {/* main tabs  */}
          <div className="flex justify-start py-4 px-4 font-semibold">
            FEATURES
          </div>

          {/* tabs div  */}
          <div className="flex flex-col gap-2 justify-between  w-full p-3 bg-white">
            <div>
              {list.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex  w-full justify-start p-1 gap-2 items-center border-b border-gray-200"
                  >
                    <img src={item.image} alt="f" className="w-10 h-10" />
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold">{item.name}</span>
                      <span className="text-xs text-gray-500">
                        Learn the basics of sports betting in just a few
                        minutes.
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <Link to="/bet-guide" className="logo">
              <div
                className="flex items-center justify-end gap-1 py-1 cursor-pointer"
                onClick={() => {}}
              >
                <span className="text-gray-400 font-semibold">Show all</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  viewBox="0 -960 960 960"
                  width="16px"
                  fill="#9ca3af"
                >
                  <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
