import { useDispatch } from "react-redux";
import { calculateOdds } from "../store/actions/homeTypes";

export const OddButtons = ({
  item,
  oddList,
  selectedOddsIds,
  availableOdds,
}) => {
  const dispatch = useDispatch();

  const handleOdd = (bet) => {
    dispatch(calculateOdds(bet));
  };
  return (
    <div className="flex flex-row gap-10" key={item.id}>
      {selectedOddsIds.map((oddId) => {
        const oddData = Object.values(oddList[item.id + "_" + oddId] ?? []);
        const captions = availableOdds[oddId + ""].captions;
        const rowCount = oddData.length / captions.length;

        return (
          <div
            key={item.id + "_" + oddId}
            className="flex justify-center items-center grid-cols-1 gap-2"
          >
            {oddData.length === 0 ? (
              <>
                {captions.map((e) => {
                  return (
                    <div
                      key={item.id + "_" + oddId + "_" + e}
                      className="flex justify-center items-center border border-gray-300 rounded-md bg-gray-200 py-2 cursor-not-allowed w-8"
                    >
                      <span className="text-gray-700">🔒</span>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div>
                  {Array.from({ length: rowCount }).map((_, rowIndex) => {
                    const start = rowIndex * captions.length;
                    const end = start + captions.length;

                    // Her satır için dilimlenmiş veriyi alıyoruz
                    const rowData = oddData.slice(start, end);

                    return (
                      <div key={rowIndex} className="flex gap-2">
                        {rowData.map((i, index) => (
                          <div
                            key={index}
                            className="flex justify-center items-center border border-gray-300 rounded-md bg-gray-200 py-2 cursor-pointer w-8"
                            onClick={() => {
                              handleOdd({
                                id: item.id + "_" + i.id,
                                matchId: item.matchId,
                                home_team_name: item.home_team_name,
                                away_team_name: item.away_team_name,
                                oddsId: i.id,
                                value: i.value,
                                market_name: i.market_name,
                              });
                            }}
                          >
                            <span className="text-gray-700">{i.value}</span>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
