import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../Input/Input"; // Ensure correct path and export
import { Button } from "../Button/Button"; // Ensure correct path and export
import SelectComponent from "../SelectComponent"; // Ensure correct export (default or named)
import CustomPhoneInput from "./CustomPhoneInput"; // Ensure correct path and export
import {
  registerValidationSchema,
  secondRegisterPageValidationSchema,
} from "../../app/schema/register"; // Ensure correct imports
import clsx from "clsx";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { registerAction } from "../../store/actions/regActions";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";
import { man, woman } from "../../assets/images";
const RegistrationForm = () => {
  const [step, setStep] = useState("step1");
  const [gender, setGender] = useState("male");

  const isStep1 = step == "step1";

  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const error = useSelector((state) => state.auth.error);
  const { isAuthenticated } = useSelector((state) => state?.authreg);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      isStep1 ? registerValidationSchema : secondRegisterPageValidationSchema
    ),
    defaultValues: {
      fname: "",
      lname: "",
      username: "",
      email: "",
      password: "",
      dateOfBirth: "",
      country: "",
      mobilePhone: "",
      gender: "",
      address: "",
      postcode: "",
      location: "",
      nationality: "",
      birthname: "",
      birthCountry: "",
      birthplace: "",
      offersAndPromotions: "",
      termsAndConditions: false,
    },
  });

  // useEffect(()=>{
  //   if (isAuthenticated) {
  //     navigate("/login");
  //   }
  // },[isAuthenticated])
  const onSubmit = async (values) => {
    if (isStep1) {
      setStep("step2");
    } else {
      setloading(true);
      const data = {
        username: values?.username,
        password: values?.password,
      };
      await dispatch(registerAction(data));
      reset();
      setloading(false);
      
        navigate("/login");
      
    }
  };

  const options = [
    { value: "germany", label: "Germany" },
    { value: "france", label: "France" },
    { value: "usa", label: "USA" },
  ];

  return (
    <div className="w-full py-16 px-12 h-full">
      {isStep1 ? (
        <div className="pb-10">
          <div className="flex justify-end gap-2 items-center w-full pb-2">
            <div onClick={(()=> setStep("step1"))} className=" relative z-50 flex items-center justify-center bg-transparent  w-8 h-8 rounded-full border-2 border-gray-500">
              1
            </div>
            <div onClick={(()=> setStep("step2"))} className="relative z-50 flex items-center justify-center bg-transparent  w-7 h-7 rounded-full border-2 border-gray-300">
              2
            </div>
          </div>
          <div className="font-medium text-2xl">Registration</div>
        </div>
      ) : (
        <div className="pb-2" onClick={(()=> setStep("step1"))}>
          <div className="flex justify-end gap-2 items-center w-full pb-2">
            <div className="flex items-center justify-center bg-black text-white  w-7 h-7 rounded-full border-2 border-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#D9D9D9"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
            </div>
            <div  className="flex items-center justify-center bg-transparent  w-8 h-8 rounded-full border-2 border-gray-500">
              1
            </div>
          </div>
        </div>
      )}
      {isStep1 ? (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="mb-4 float-left w-full">
            <label
              className={clsx(
                "block text-black font-normal leading-[21.97px] mb-2 text-base",
                {
                  "text-center": false,
                }
              )}
            >
              Your Country
            </label>

            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#f3f4f6",
                      height: "47px",
                      borderRadius: "6px",
                      borderColor: "#757575",
                      color: "#757575",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#757575",
                      },
                    }),
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                />
              )}
            />

            {errors?.country?.message && (
              <div className="text-red-500 mt-2 text-sm">
                {errors?.country?.message}
              </div>
            )}
          </div>

          <SimpleInput
            type="date"
            label={"*Date of Birth"}
            className="w-full"
            errormessage={errors.dateOfBirth?.message}
            {...register("dateOfBirth", { required: true })}
          />
          <div className="grid md:grid-cols-2 gap-3 float-left w-full">
            <SimpleInput
              type="text"
              label={"First Name"}
              className="w-full"
              errormessage={errors?.fname?.message}
              {...register("fname", { required: true })}
            />
            <SimpleInput
              type="text"
              label={"Surname"}
              className="w-full"
              errormessage={errors?.surname?.message}
              {...register("surname", { required: true })}
            />
          </div>
          <SimpleInput
            type="text"
            label={"*User Name"}
            className="w-full"
            errormessage={errors?.username?.message}
            {...register("username", { required: true })}
          />
          <SimpleInput
            type="email"
            label={"E-mail"}
            className="w-full"
            errormessage={errors?.email?.message}
            {...register("email", { required: true })}
          />
          <SimpleInput
            type="password"
            label={"Password"}
            errormessage={errors?.password?.message}
            {...register("password", { required: true })}
          />
          <div>
            <label
              className={clsx(
                "block text-black font-normal leading-[21.97px] mb-2 text-base",
                {
                  "text-center": false,
                }
              )}
            >
              {"Mobile Phone (optional)"}
            </label>
            <CustomPhoneInput
              onChange={(value) => setValue("mobilePhone", value)}
            />
          </div>
          <Button
            variant="primary"
            className="w-full h-[57px] bg-[#4D90A7] hover:bg-[#5198af] text-white rounded-md"
            type="submit"
          >
            Continue
          </Button>
          <div className="mt-12 text-center">
            <a href="#" className="text-sm text-blue-600 hover:underline">
              Back to Login
            </a>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-lg mx-auto p-4"
        >
          {/* <button className="text-lg" onClick={() => setStep("step1")}>
            Back
          </button> */}
          <div className="mb-4">
            <label
              className={clsx(
                "block text-black font-normal leading-[21.97px] mb-2 text-base",
                {
                  "text-center": false,
                }
              )}
            >
              * Anutation
            </label>
            <div className="flex w-full h-14 justify-between gap-5">
              {/* man  */}
              <div className="flex h-full w-full bg-gray-200 items-center gap-3 py-1 px-2 rounded-md cursor-pointer" onClick={()=>{
                setGender("male")
              }}>
            <div className={`${gender=='male'?"":"opacity-20"}  w-12 h-full`}>
               <div className="flex items-center justify-center rounded-full bg-gray-200 w-12 h-full border border-black overflow-hidden">
                  <img
                    src={man}
                    alt="man"
                    className=" object-cover overflow-hidden mt-4"
                  />
                </div>
               </div>
                <span className="font-semibold"> Lord</span>
              </div>

              {/* Female  */}
              <div className="flex h-full w-full bg-gray-200 items-center gap-3 py-1 px-2 rounded-md cursor-pointer"  onClick={()=>{
                setGender("female")
              }}>
             <div className={`${gender=='female'?" rounded-full ":"opacity-20"}  w-12 h-full`}>
             <div className="flex items-center justify-center rounded-full bg-gray-200  w-12 h-full border border-black overflow-hidden">
                  <img
                    src={woman}
                    alt="woman"
                    className=" object-cover overflow-hidden mt-4"
                  />
                </div>
             </div>
                <span className="font-semibold"> Woman</span>
              </div>
            </div>
          </div>
          <SimpleInput
            type="text"
            label={"*Street and house number"}
            className="w-full"
            errormessage={errors?.streetAndHouseNumber?.message}
            {...register("streetAndHouseNumber", { required: true })}
          />
          <div className="flex mb-4 space-x-4">
            <SimpleInput
              type="text"
              label={"*Postcode"}
              className="w-1/3"
              errormessage={errors?.postcode?.message}
              {...register("postcode", { required: true })}
            />
            <SimpleInput
              type="text"
              label={"*Location"}
              className="w-full"
              errormessage={errors?.location?.message}
              {...register("location", { required: true })}
            />
          </div>
          <div className="mb-4">
            <label
              className={clsx(
                "block text-black font-normal leading-[21.97px] mb-2 text-base",
                {
                  "text-center": false,
                }
              )}
            >
              * Nationality
            </label>
            <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#f3f4f6",
                      height: "47px",
                      borderRadius: "6px",
                      borderColor: "#757575",
                      color: "#757575",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#757575",
                      },
                    }),
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                />
              )}
            />

            {errors?.nationality?.message && (
              <div className="text-red-500 mt-2 text-sm">
                {errors?.nationality?.message}
              </div>
            )}
          </div>

          <SimpleInput
            type="text"
            label={"*Birth name"}
            className="w-full"
            errormessage={errors?.birthName?.message}
            {...register("birthName", { required: true })}
          />

          <div className="mb-4">
            <label
              className={clsx(
                "block text-black font-normal leading-[21.97px] mb-2 text-base",
                {
                  "text-center": false,
                }
              )}
            >
              * BirthCountry
            </label>
            <Controller
              name="birthCountry"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#f3f4f6",
                      height: "47px",
                      borderRadius: "6px",
                      borderColor: "#757575",
                      color: "#757575",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#757575",
                      },
                    }),
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                />
              )}
            />
          </div>
          <SimpleInput
            type="text"
            label={"* Birth place"}
            className="w-full"
            errormessage={errors?.birthplace?.message}
            {...register("birthplace", { required: true })}
          />
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              * Offers and promotions
            </label>
            <div className="flex flex-col space-y-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  {...register("offersAndPromotions")}
                  value="yes"
                />
                <span>Yes, I want to receive free bets and other offers</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  {...register("offersAndPromotions")}
                  value="no"
                />
                <span>No, I don't want to receive offers</span>
              </label>
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              General Terms and Conditions
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                {...register("termsAndConditions", { required: true })}
              />
              <span>I accept the terms and conditions</span>
            </label>
          </div>

          <Stack>
            {loading ? (
              <div className="flex justify-center items-center">
                <CircularProgress />
              </div>
            ) : (
              <Button
                variant="primary"
                className="w-full h-[57px] bg-[#4D90A7] hover:bg-[#5198af] text-white rounded-md"
                type="submit"
              >
                Register
              </Button>
            )}
          </Stack>
          <div className="mt-12 text-center">
            <a href="/login" className="text-sm text-[#4D90A7] hover:underline">
              Back to Login
            </a>
          </div>
        </form>
      )}
    </div>
  );
};

export default RegistrationForm;
