const initialState = {
  showDetail: false,
  odds: [],
};
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_DETAIL":
      return {
        ...state,
        showDetail: !state.showDetail, // Toggle sidebar state
      };

    case "CALCULATE_ODD":
      return {
        ...state,
        odds: state.odds.some((odd) => odd.id === action.payload.id)
          ? state.odds.map((odd) =>
              odd.id === action.payload.id ? { ...odd, ...action.payload } : odd
            )
          : [...state.odds, action.payload],
      };
    case "REMOVE_ODD":
      return {
        ...state,
        odds: state.odds.filter((odd) => !(odd.id === action.id)), // Remove the odd by comparing id
      };

    case "CLEAR_ODD":
      return {
        ...state,
        odds: [], // Remove the odd by comparing id
      };

    default:
      return state;
  }
};

export default homeReducer;
