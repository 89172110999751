import React from "react";
import { baseball, shirt1, shirt2 } from "../../../assets/images/index";
import { useDispatch } from "react-redux";
import { showOddDetail } from "../../../store/actions/homeTypes";



const DetailCard = ({  showDetail, hndleDetail }) => {
  const dispatch = useDispatch();
    return (
      <div className="w-full h-full bg-gray-200 pt-6 text-white">
        <div className="w-full h-full bg-black">
          {/* top  */}
          <div className="flex justify-between p-1 w-full">
            <div></div>
            <div className="grid grid-cols-1 text-center ">
              <span className="text-lg">Permier League</span>
              <span className="text-xs text-gray-300">England</span>
            </div>
            <div onClick={()=>{
              dispatch(showOddDetail())
            }} className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
          </div>
          {/* // three tabs  */}
          <div className="flex w-full justify-evenly mt-2">
            <div className="flex flex-col items-center">
              <img src={shirt1} alt="" width={35} height={35} />
              <span className="text-sm">FC Fulham</span>
            </div>
            <div className="flex flex-col items-center">
              <img src={baseball} alt="" width={35} height={35} />
              <span className="text-sm">FC Fulham</span>
            </div>
            <div className="flex flex-col items-center">
              <img src={shirt2} alt="" width={35} height={35} />
              <span className="text-sm">FC Fulham</span>
            </div>
          </div>
          {/* // main Section  */}
          <div className="w-full px-3 pt-2 h-full overflow-auto">
            <div className="flex w-full justify-between px-3 bg-white h-full text-gray-200 "></div>
          </div>
        </div>
      </div>
    );
  };


export {DetailCard}