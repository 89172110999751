import axios from "axios";
import { TOPMATCHES_SUCCESS, TOPMATCHES_FAILURE } from "./actiontypes";
import { top_matches } from "../paths";


export const TopMatchesApi = (credentials) => async (dispatch) => {



  try {
    // if (token) {
   
      const response = await axios.post(top_matches, credentials);
      dispatch({
        type: TOPMATCHES_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    // } else {
      // dispatch({
      //   type: MATCHES_FAILURE,
      //   payload: "api failed",
      // });
    // }
  } catch (error) {
    dispatch({
      type: TOPMATCHES_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};


