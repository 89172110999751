import { useDispatch } from "react-redux";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { football, shirt1, shirt2 } from "../../assets/images";




const data = [
    {
      id: 1,
      time: "18:30",
      date: "Heute",
      team1: "RB Leipzig",
      team2: "Union Berlin",
      team1Img: shirt1,
      team2Img: shirt2,
      odds: [1.43, 2.43, 3.43],
      stats: 123,
    },
    {
      id: 2,
      time: "20:45",
      date: "Morgen",
      team1: "Bayern Munich",
      team2: "Borussia Dortmund",
      team1Img: shirt1,
      team2Img: shirt2,
      odds: [1.55, 2.55, 3.55],
      stats: 145,
    },
    {
      id: 3,
      time: "16:00",
      date: "Übermorgen",
      team1: "Barcelona",
      team2: "Real Madrid",
      team1Img: shirt1,
      team2Img: shirt2,
      odds: [1.25, 2.35, 3.25],
      stats: 167,
    },
    {
      id: 4,
      time: "14:30",
      date: "Heute",
      team1: "Manchester United",
      team2: "Manchester City",
      team1Img: shirt1,
      team2Img: shirt2,
      odds: [1.75, 2.85, 3.75],
      stats: 189,
    },
    {
      id: 5,
      time: "22:00",
      date: "Morgen",
      team1: "PSG",
      team2: "Marseille",
      team1Img: shirt1,
      team2Img: shirt2,
      odds: [1.65, 2.65, 3.65],
      stats: 201,
    },
    {
      id: 6,
      time: "19:15",
      date: "Heute",
      team1: "Juventus",
      team2: "Inter Milan",
      team1Img: shirt1,
      team2Img: shirt2,
      odds: [1.95, 2.95, 3.95],
      stats: 222,
    },
  ];


  export function EuropeanFootball({inMain=false}){
    const dispatch = useDispatch();
    const [betTab, setBet] = useState('Football');
    const [datas, setData] = useState(['Football','All']);
   
    return <div className="py-6">
          <CardList
          showDetail={false}
          hndleDetail={()=>{}}
          betTab={betTab}
        />
  
    </div>
}


const CardList = ({ showDetail, hndleDetail, betTab }) => {
    const cards = [
      {
        id: 1,
        header: "Spain / La Liga",
        content: "This is the content for card 1 in List One",
      },
      {
        id: 2,
        header: "England / Premier League",
        content: "This is the content for card 2 in List One",
      },
      {
        id: 3,
        header: "Germany / Bundesliga",
        content: "This is the content for card 3 in List One",
      },
      {
        id: 4,
        header: "Italy / Serie A",
        content: "This is the content for card 4 in List One",
      },
      {
        id: 5,
        header: "France / Ligue 1",
        content: "This is the content for card 5 in List One",
      },
    ];
    const cards2 = [
      {
        id: 6,
        header: "USA / MLS",
        content: "This is the content for card 1 in List Two",
      },
      {
        id: 7,
        header: "Brazil / Serie A",
        content: "This is the content for card 2 in List Two",
      },

    ];
  
    const {
      control,
      register,
      handleSubmit,
      setValue,
      getValues,
      reset,
      watch,
      formState: { errors },
    } = useForm({
      mode: "onChange",
      // resolver: yupResolver(),
    });
  
    return (
      <div className={` md:w-full shadow-xl `}>
        {/* Sticky Hello Section */}
        <div className="sticky flex justify-between top-0 gap-3 pr-5 bg-gray-100  pt-6 z-20 px-3">
            <div className="flex items-center gap-2">
                <img src={football} alt="football" width={35} height={35} />
                <span className="font-semibold">Football</span>
            </div>
         <div className="flex gap-4">
         <div className={`${showDetail ? "hidden" : ""}`}>
            <Controller
              name="betone"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    { value: "Tip", label: "Tip" },
                    { value: "About/Under", label: "About/Under" },
                  ]}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#fff",
                      height: "36px",
                      width: "140px",
                      borderRadius: "6px",
                      borderColor: "#757575",
                      color: "#757575",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#757575",
                      },
                    }),
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                />
              )}
            />
  
         
          </div>
  
          <div>
            <Controller
              name="bettwo"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    { value: "Tip", label: "Tip" },
                    { value: "About/Under", label: "About/Under" },
                  ]}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#fff",
                      height: "36px",
                      width: "140px",
                      borderRadius: "6px",
                      borderColor: "#757575",
                      color: "#757575",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#757575",
                      },
                    }),
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                />
              )}
            />
          </div>
         </div>
        </div>
  
        {/* Card List */}
        {(betTab === "Football" ? cards : cards2).map((card) => (
          <div key={card.id} className="bg-white w-full">
            {/* Sticky Card Header */}
            <div
              className={` sticky grid grid-cols-8 lg:grid-cols-12 top-[60px] bg-gray-100 border-b border-gray-300 pt-5 pb-1 px-3 z-10`}
            >
              <div
                className={`${
                  showDetail
                    ? "text-[10px] font-semibold"
                    : "text-sm col-span-4 font-semibold "
                } lg:col-span-5   flex gap-1  `}
              >
                {card.header}
  
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16px"
                  viewBox="0 -960 960 960"
                  width="16px"
                  fill="#676767"
                >
                  <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
                </svg>
              </div>
  
              <div
                className={`${
                  showDetail ? "col-span-5 px-4" : "col-span-3"
                } flex justify-between px-6 font-semibold text-xs text-gray-400 `}
              >
                <span>1</span>
                <span>X</span>
                <span>2</span>
              </div>
              <div className="col-span-1 hidden lg:block"></div>
              <div
                className={`col-span-2   ${
                  showDetail ? "hidden" : "hidden lg:block"
                }`}
              >
                <div className="flex justify-between pl-4 pr-9  font-semibold text-xs text-gray-400">
                  <span>+</span>
                  <span>--</span>
                </div>
              </div>
              <div className="col-span-1 "></div>
            </div>
  
            {/* Card Content */}
            <div className=" w-full border-b border-gray-300 ">
              {data.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="grid grid-cols-8 lg:grid-cols-12 cursor-pointer hover:bg-gray-100 items-center gap-2 w-full px-4 py-2 border-b border-gray-300"
                    onClick={hndleDetail}
                  >
                    {/* 1 col  */}
                    <div
                      className={`${
                        showDetail ? "col-span-2" : "col-span-1"
                      } grid grid-cols-1 text-xs text-gray-500 truncate`}
                    >
                      <span>{item.date}</span>
                      <span>{item.time}</span>
                    </div>
                    {/* 4 col  */}
                    <div
                      className={`${
                        showDetail ? "col-span-4 " : " col-span-3 lg:col-span-4"
                      }    text-xs font-semibold text-black`}
                    >
                      <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
                        <img src={item.team1Img} alt="" width={20} height={20} />
                        <span>{item.team1}</span>
                      </div>
                      <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
                        <img src={item.team2Img} alt="" width={20} height={20} />
                        <span>{item.team2}</span>
                      </div>
                    </div>
                    {/* 3 col   */}
                    <div
                      className={`${
                        showDetail
                          ? "col-span-4 text-[10px] "
                          : "col-span-3 text-[10px] md:text-base "
                      } text-center grid grid-cols-3 gap-1 items-center `}
                    >
                      {item.odds.map((i) => {
                        return (
                          <div
                            key={i}
                            className="border border-gray-300 rounded-md bg-gray-200 py-1    cursor-pointer"
                          >
                            {`${i}`}
                          </div>
                        );
                      })}
                    </div>
                    {/* 4 col  */}
                    <div
                      className={`${
                        showDetail
                          ? "col-span-1  hidden"
                          : "col-span-1 hidden lg:block"
                      } `}
                    >
                      <span className="flex justify-end">2,3</span>
                    </div>
                    <div
                      className={`${
                        showDetail
                          ? "col-span-2  hidden"
                          : "col-span-2 hidden lg:block "
                      } `}
                    >
                      <div className="flex gap-1  ">
                        {[1, 2].map((i) => {
                          return (
                            <div
                              key={i}
                              className="border border-gray-300 rounded-md bg-gray-200 py-1 px-2  cursor-pointer"
                            >
                              {`${i},43`}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-span-1 grid items-center">
                      <div className="flex  items-center justify-end">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16px"
                            viewBox="0 -960 960 960"
                            width="16px"
                            fill="#B7B7B7"
                          >
                            <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
                          </svg>
                        </span>
                        <span className="text-xs">{item.stats}</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="14px"
                            viewBox="0 -960 960 960"
                            width="14px"
                            fill="#CCCCCC"
                          >
                            <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );


  };