import { useState } from "react";

export default function BetGuide() {
 
  return (
    <div className="flex w-full h-[95vh]">
      <div
        className={`w-full flex justify-center h-full bg-gray-200 px-5  overflow-auto scrollbar-hide`}
      >
        <div className="bg-white w-1/2 m-10">
          <BetGuideSection />
        </div>
      </div>
    </div>
  );
}

function BetGuideSection() {
  return (
    <div className="mt-2 px-4">
      <span className="font-semibold flex justify-center">Bet Guide</span>
      <span className="text-center flex justify-center text-base">
        Learn the basics of sports etting in our short 2 minute video or scroll
        down to find more infromation.
      </span>
      <div className="flex w-full bg-gray-300 h-40 my-4"></div>

      <div>
        <span className="font-semibold">What are odds?</span>
      </div>
    </div>
  );
}

function CarCashoutSection() {
  return (
    <div className="mt-2 px-4">
      <span className="font-semibold flex justify-center">Bet Guide</span>
      <span className="text-center flex justify-center text-base">
        Learn the basics of sports etting in our short 2 minute video or scroll
        down to find more infromation.
      </span>
      <div className="flex w-full bg-gray-300 h-40 my-4"></div>

      <div>
        <span className="font-semibold">What are odds?</span>
      </div>
    </div>
  );
}
function FavoriteSection() {
  return (
    <div className="mt-2 px-4">
      <span className="font-semibold flex justify-center">Bet Guide</span>
      <span className="text-center flex justify-center text-base">
        Learn the basics of sports etting in our short 2 minute video or scroll
        down to find more infromation.
      </span>
      <div className="flex w-full bg-gray-300 h-40 my-4"></div>

      <div>
        <span className="font-semibold">What are odds?</span>
      </div>
    </div>
  );
}
function CashoutSection() {
  return (
    <div className="mt-2 px-4">
      <span className="font-semibold flex justify-center">Bet Guide</span>
      <span className="text-center flex justify-center text-base">
        Learn the basics of sports etting in our short 2 minute video or scroll
        down to find more infromation.
      </span>
      <div className="flex w-full bg-gray-300 h-40 my-4"></div>

      <div>
        <span className="font-semibold">What are odds?</span>
      </div>
    </div>
  );
}
