import React, { useState } from "react";

const CustomSwitch = ({ roundCorner = true }) => {
  const [getSelectionMode, setSelectionMode] = useState(1);

  const updatedSwitchData = (val) => {
    setSelectionMode(val);
  };

  return (
    <div className="flex justify-center items-center">
      <div
        className={`flex w-48 h-9 bg-gray-200 border border-gray-300   rounded-full`}
      >
        <button
          onClick={() => updatedSwitchData(1)}
          className={`flex-1 flex text-xs font-medium justify-center items-center ${
            getSelectionMode === 1
              ? `rounded-full bg-white border border-gray-100 shadow-md`
              : ""
          } `}
        >
          {"Time"}
        </button>
        <button
          onClick={() => updatedSwitchData(2)}
          className={`flex-1 flex text-xs font-medium justify-center items-center ${
            getSelectionMode === 2
              ? `rounded-full bg-white border border-gray-100 shadow-md`
              : ""
          } `}
        >
          {"Competation"}
        </button>
      </div>
    </div>
  );
};

export default CustomSwitch;
