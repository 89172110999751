const showOddDetail = () => {
  return {
    type: "SHOW_DETAIL",
  };
};

const calculateOdds = (payload) => {
  return {
    type: "CALCULATE_ODD",
    payload: {
      id: payload.id,
      matchId: payload.matchId,
      home_team_name: payload.home_team_name,
      away_team_name: payload.away_team_name,
      oddsId: payload.oddsId,
      value: payload.value,
      market_name: payload.market_name,
    },
  };
};

const removeOdd = (bet) => {
  return {
    type: "REMOVE_ODD",
    id: bet.id,
    oddValue: bet.oddValue,
  };
};

const clearOdds = () => {
  return {
    type: "CLEAR_ODD",
  };
};

export { showOddDetail, calculateOdds, removeOdd, clearOdds };
