import React, { useState, useRef, useEffect } from "react";
import { shirt1 } from "../../../assets/images";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedItem } from "../../../store/actions/sidebarActions";
export default function DashboardSlider({ isMarketSectionVisible }) {
  return <Carousel />;
}

const Carousel = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards = [
    { id: 1, text: "Card 1" },
    { id: 2, text: "Card 2" },
    { id: 3, text: "Card 3" },
    { id: 4, text: "Card 4" },
    { id: 5, text: "Card 5" },
    { id: 6, text: "Card 6" },
  ];

  const visibleCards = 3; // Number of cards visible in one row
  const totalCards = cards.length;

  // Navigate to the next set of cards 
  const nextSlide = () => {
    if (currentIndex < totalCards - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Navigate to the previous set of cards
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const data = [0, 1, 2, 4];
  return (
    <div className="relative w-full">
      {/* top */}
      <div
        className={`flex items-center justify-between w-full gap-4 pt-8 pb-2`}
      >
        <div className="flex flex-col">
          <span className="font-semibold">Top-Kombis</span>
          <span className="font-normal text-xs text-gray-400">
            from other Tipico customers
          </span>
        </div>
        <div className="flex items-center">
          <span className="font-Source-Code-Pro font-semibold text-sm" onClick={()=>{
             dispatch(setSelectedItem('top-combis'))
          }}>
            Show all
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 -960 960 960"
            width="18px"
            fill="#B7B7B7"
          >
            <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
          </svg>
        </div>
      </div>

      <div className="overflow-hidden">
        {/* Carousel Wrapper */}

        <div
          className="flex transition-transform duration-500 ease-in-out px-4 "
          style={{
            transform: `translateX(-${currentIndex * (100 / visibleCards)}%)`,
          }}
        >
          {cards.map((card, index) => (
            <div
              key={index}
              className="w-72 flex flex-col h-full  mr-1 rounded-md bg-white" // Each card takes up 1/3rd of the width
            >
              {/* First Section  */}
              <div className="w-72 flex justify-evenly items-center  h-16 bg-white border-b border-gray-200 pr-1">
                <div className="flex flex-col items-center">
                  <div className="flex gap-1">
                    {/* 4396b3 */}
                    <span className="bg-green-700 font-semibold text-white px-1 flex ">
                      6780
                    </span>
                    <div>
                      <span className="animate-blink opacity-0 font-extrabold text-green-700">
                        &#11014;
                      </span>
                    </div>
                  </div>
                  <span>Bets placed</span>
                </div>


                <div className="flex flex-col items-center">
                  <span>6s</span>
                  <span>Combi</span>
                </div>


                <div className="flex flex-col items-center">
                  <span>11,18</span>
                  <span>Total qouta</span>
                </div>
              </div>



              {/* Second Section  */}
              <div className="w-72 flex flex-col overflow-y-hidden justify-evenly items-start px-2 my-2 h-40 bg-white border-b border-gray-200 pr-1 overflow-auto">
                {data.map((i, index) => (
                  <div className="flex flex-col px-3 py-1" key={index}>
                    <div className="flex items-center">
                      <img src={shirt1} alt="" className="mr-2" />
                      Aston Villa
                    </div>
                    <div className={`${index==2&& 'opacity-25'} text-sm font-medium text-gray-500`}>
                      BSC Young Boys {index}
                    </div>
                  </div>
                ))}
              </div>
              {/* Third Section  */}
              <div className="flex w-full py-2 font-normal bg-white text-blue-400 justify-center items-center">
                See the batting slip A{index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      {currentIndex > 0 && (
        <button
          onClick={prevSlide}
          className="absolute w-8 h-8 top-[200px] -left-3 shadow-lg shadow-gray-300 transform -translate-y-1/2 bg-white text-black rounded-full"
        >
          &#10094;
        </button>
      )}
      {currentIndex < totalCards - visibleCards && (
        <button
          onClick={nextSlide}
          className="absolute w-8 h-8 top-[200px] -right-3  shadow-lg shadow-gray-300  transform -translate-y-1/2  text-black rounded-full bg-white "
        >
          &#10095;
        </button>
      )}
    </div>
  );
};
