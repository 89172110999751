import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { shirt1, shirt2 } from "../../assets/images";
import CustomSwitch from "../CustomSwitch";

export function TopCombis({ inMain = false }) {
  const dispatch = useDispatch();
  const [betTab, setBet] = useState();
  const [datas, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://38.180.122.61:3000/api/mapping/sports"
        );
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    console.log(betTab);
    if (datas) {
      if (datas.length > 0) {
        setBet(datas[0].name);
      }
    }
  }, [datas]);
  return (
    <div className="pt-8 ">
      {/* top */}
      {!inMain && (
        <div className={`flex items-center w-full gap-4  pb-4`}>
          <span>Sort by:</span>
          <CustomSwitch />
        </div>
      )}
      {/* Tabs  */}
      <div
        className={`${
          false ? "w-1/2" : "w-full"
        } bg-white shadow-sm rounded-sm my-4`}
      >
        <div className="flex items-center w-full h-14  shadow-bottom-only  overflow-auto scrollbar-hide">
          {datas.map((values, index) => {
            return (
              <div
                key={index}
                className={`flex items-center cursor-pointer px-3 h-full border-b-2 ${
                  betTab == values?.name
                    ? "border-b-2  border-red-500"
                    : "border-b-2  border-gray-50"
                } text-center`}
                onClick={async () => {
                  setBet(values?.name);
                }}
              >
                <span className="font-semibold truncate font-Source-Code-Pro text-sm">
                  {values?.name}
                </span>
              </div>
            );
          })}
        </div>
        <div className="p-12">
        {
            [1,2,3,4].map((item,index)=>{
                return   <div key={index} className="w-full flex flex-col my-2 rounded-lg border border-gray-200 ">
                <div className="flex items-center py-2 px-8 gap-12 border-b border-gray-200">
                  <div className="flex flex-col items-center ">
                    <div className="flex gap-1">
                      {/* 4396b3 */}
                      <span className="bg-green-700 rounded-sm font-semibold text-white px-1 flex ">
                        6780
                      </span>
                      <div>
                        <span className="animate-blink opacity-0 font-extrabold text-green-700">
                          &#11014;
                        </span>
                      </div>
                    </div>
                    <span className="text-sm text-gray-500 font-medium">Bets placed</span>
                  </div>
    
                  <div className="flex flex-col items-center">
                    <span>6s</span>
                    <span className="text-sm text-gray-500 font-medium">Combi</span>
                  </div>
    
                  <div className="flex flex-col items-center">
                    <span>11,18</span>
                    <span className="text-sm text-gray-500 font-medium">Total qouta</span>
                  </div>
                </div>
    
                {/* // Second section */}
                <div className="flex items-center justify-between py-2 px-4">
                <div className="flex flex-col px-3 py-1" >
                        <div className="flex items-center">
                          <img src={shirt1} alt="" className="mr-2" />
                          Celtic FC
                        </div>
                        <div className={`text-sm font-medium text-gray-500`}>
                            Celtic FC - Slovan Bratislava
                        </div>
                      </div>
    
                      <div className="flex flex-col px-3 py-1" >
                        <div className="flex items-center">
                          <img src={shirt1} alt="" className="mr-2" />
                          Aston Villa
                        </div>
                        <div className={`text-sm font-medium text-gray-500`}>
                          BSC Young Boys
                        </div>
                      </div>
    
                      <div className="bg-[#4396b3] text-white px-5 py-2 rounded-md">
                            See the betting slip A1
                      </div>
                </div>
              </div>
            })
        }
        </div>
      </div>
    </div>
  );
}
