import React, { useState } from 'react'
import { americanFootball, basketball, calendar, combination, darts, earth, football, german, icehockey, liga, ligapro, motorspot, olympics, primera, snooker, soccer, tennis, topevents } from '../../assets/images'
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedItem, toggleSidebar } from '../../store/actions/sidebarActions';
export const quickLinks = [
  {
    icon: topevents,
    title: "Top Events",
    value: "top-events"
  },
  {
    icon: combination,
    title: "Top Combis",
    value: "top-combis"
  },
  {
    icon: calendar,
    title: "Today's Matches",
    value: "todays-matches"
  },
  {
    icon: german,
    title: "German Football",
    value: "german-football"
  },
  {
    icon: earth,
    title: "European Football",
    value: "european-football"
  },
]

const topCompetitions = [
  {
    icon: primera,
    title: "Primera Division, Clausura",
    value: "primera-division"
  },
  {
    icon: ligapro,
    title: "Europa Conferrence League",
    value: "europa-conferrence"
  },
  {
    icon: liga,
    title: "2. Bundesliga",
    value: "bundesliga"
  },
  {
    icon: football,
    title: "Europa League",
    value: "europa-league"
  },
  {
    icon: football,
    title: "Super Lig",
    value: "super-lig"
  },
]

const topSports = [
  {
    icon: football,
    title: "Football"
  },
  {
    icon: olympics,
    title: "Olympic Games"
  },
  {
    icon: tennis,
    title: "Tennis"
  },
  {
    icon: americanFootball,
    title: "American"
  },
  {
    icon: basketball,
    title: "Basketball"
  },
]

const topSportsToday = [
  {
    icon: football,
    title: "Football"
  },
  {
    icon: tennis,
    title: "Tennis"
  },
  {
    icon: americanFootball,
    title: "American"
  },
  {
    icon: basketball,
    title: "Basketball"
  },
]

const topSports3hrs = [
  {
    icon: football,
    title: "Football"
  },
  {
    icon: olympics,
    title: "Olympic Games"
  },
  {
    icon: basketball,
    title: "Basketball"
  },
]

const topSports48hrs = [
  {
    icon: football,
    title: "Football"
  },
  {
    icon: olympics,
    title: "Olympic Games"
  },
  {
    icon: tennis,
    title: "Tennis"
  },
]

const AtoZ = [
  {
    icon: americanFootball,
    title: "American Football"
  },
  {
    icon: soccer,
    title: "Aussie Rules"
  },
  {
    icon: basketball,
    title: "Basketball"
  },
  {
    icon: darts,
    title: "Darts"
  },
  {
    icon: football,
    title: "Football"
  },
  {
    icon: icehockey,
    title: "Ice Hockey"
  },
  {
    icon: motorspot,
    title: "Motorsport"
  },
  {
    icon: olympics,
    title: "Olympic Games"
  },
  {
    icon: snooker,
    title: "Snooker"
  },
  {
    icon: tennis,
    title: "Tennis"
  },
]


const tabList=['All','Today','3 hours','48 hours']



const Sidebar = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('All');
  const [activeMenu, setActiveMenu] = useState('top-events');

  const handleToggle = () => {
    dispatch(toggleSidebar());
  };

  const handleActiveMenu = (menu) => {
    setActiveMenu(menu);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSelectItem = (item) => {
    dispatch(setSelectedItem(item));
    handleActiveMenu(item); 
  };

  return (
    <div className={`hidden sm:block w-1/4 lg:w-1/6 h-[95vh] overflow-auto p-3 shadow-sm bg-gray-200 scrollbar-hide`}>
      <div className="w-full">
        <h6 className="text-gray-500 mb-4">Quick access</h6>
        <ul className="space-y-2">
          {quickLinks.map((row) => (
            <li
              className={`flex items-center cursor-pointer p-1 rounded hover:bg-white ${
                activeMenu === row.value ? 'bg-white font-semibold' : ''
              }`}
              onClick={() => handleSelectItem(row.value)}
              key={row.value}
            >
              <img className="mr-3" src={row.icon} alt="icon" width={20} height={20} />
              <span className='text-xs md:text-sm'>{row.title}</span>
            </li>
          ))}
        </ul>
        
        <h6 className="text-gray-500 py-4">Top Competitions</h6>
        <ul className="space-y-2">
          {topCompetitions.map((row) => (
            <li
              className={`flex items-center cursor-pointer p-1 rounded hover:bg-white ${
                activeMenu === row.value ? 'bg-white font-semibold' : ''
              }`}
              onClick={() => handleSelectItem(row.value)}
              key={row.value}
            >
              <img className="mr-3" src={row.icon} alt="icon" width={20} height={20} />
              <span className='text-xs md:text-sm'>{row.title}</span>
            </li>
          ))}
        </ul>

        <div className="mt-6">
          <div className="flex items-center space-x-4 mb-4 overflow-auto">
            {
              tabList.map((item, i) => (
                <div key={i} className={`flex flex-wrap items-center font-semibold cursor-pointer h-8 text-center text-xs text-gray-500 ${activeTab === item ? "bg-white px-4 py-2 border-b-2 border-red-500" : ""}`}   
                     onClick={() => handleTabClick(item)}>
                  {item}
                </div>
              ))
            }
          </div>

          <h6 className="text-gray-500 mb-4">Top Sports</h6>
          <ul className="space-y-2">
            {(activeTab === 'All' ? topSports : activeTab === 'Today' ? topSportsToday : activeTab === '3Hrs' ? topSports3hrs : topSports48hrs).map((row) => (
              <li
                className="flex items-center cursor-pointer p-2 rounded hover:bg-gray-200"
                onClick={() => handleSelectItem(row.value)} // Update based on the row value
                key={row.value}
              >
                <img className="mr-3" src={row.icon} alt="icon" width={20} height={20} />
                <span className='text-xs md:text-sm'>{row.title}</span>
              </li>
            ))}
          </ul>

          {activeTab === 'All' && (
            <>
              <h6 className="text-gray-500 mt-6 mb-4">A To Z</h6>
              <ul className="space-y-2">
                {AtoZ.map((row) => (
                  <li
                    className="flex items-center cursor-pointer p-1 rounded hover:bg-gray-200 mb-2"
                    onClick={() => handleSelectItem(row.value)} // Update based on the row value
                    key={row.value}
                  >
                    <img className="mr-3" src={row.icon} alt="icon" width={20} height={20} />
                    <span className='text-xs md:text-sm'>{row.title}</span>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};



export default Sidebar;
