import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const axiosAuth= axios.create({
  baseURL: `${BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosAuth;