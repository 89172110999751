import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { shirt1, shirt2 } from "../../assets/images";
import CustomSwitch from "../CustomSwitch";
import { useQuery } from "@tanstack/react-query";
import axiosAuth from "../../app/axios";
import { calculateOdds, showOddDetail } from "../../store/actions/homeTypes";
import { MatchesApi } from "../../store/actions/matchesApi";
import moment from "moment";

export function TopEvents({ inMain = false }) {
  // const showDetail = useSelector((state) => state.homereducer.showDetail);
  const dispatch = useDispatch();
  const [betTab, setSelectedTab] = useState();
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ["topEvents"],
    queryFn: async () => {
      const response = await axiosAuth.get(`mapping/sports`);
      return await response.data;
    },
  });
  useEffect(() => {
    if (data) {
      if (data.length > 0) {
        setSelectedTab(data[0].name);
      }
    }
  }, [data]);
  if (isPending) return "Loading...";
  if (isFetching) return "Fetching...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className="pt-8">
      {/* top */}
      {!inMain && (
        <div className={`flex items-center w-full gap-4  pb-4`}>
          <span>Sort by:</span>
          <CustomSwitch />
        </div>
      )}
      {/* Tabs  */}
      <div
        className={`${
          true ? "w-1/2" : "w-full"
        } flex items-center w-full h-14 bg-white overflow-auto scrollbar-hide`}
      >
        {data?.length > 0 &&
          data?.map((values, index) => {
            return (
              <div
                key={index}
                className={`flex items-center cursor-pointer px-3 h-full border-b-2 ${
                  betTab == values?.name
                    ? "border-b-2  border-red-500"
                    : "border-b-2  border-gray-50"
                } text-center`}
                onClick={async () => {
                  setSelectedTab(values?.name);

                  await dispatch(
                    MatchesApi({
                      pageNumber: 0,
                      pageSize: 10,
                      sport_id: values?.id,
                    })
                  );
                }}
              >
                <span className="font-semibold truncate font-Source-Code-Pro text-sm">
                  {values?.name}
                </span>
              </div>
            );
          })}
      </div>
      <CardList showDetail={false} hndleDetail={() => {}} betTab={betTab} />
      {/* <div className="flex justify-end items-center text-gray-500 gap-2 p-2">
        <span>All Events</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="14px"
          viewBox="0 -960 960 960"
          width="14px"
          fill="#CCCCCC"
        >
          <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
        </svg>
      </div> */}
    </div>
  );
}

const CardList = ({ showDetail, hndleDetail, betTab }) => {
  // const oddsList = useSelector((state) => state.homereducer.odds);
  const oddsList = [1.43, 6.43, 3.43];
  const oddsList2 = [1.43, 2.43];
  const dispatch = useDispatch();
  const betSlip = useSelector((state) => state.homereducer);

  console.log(betSlip);

  const handleOdd = (bet) => {
    dispatch(calculateOdds(bet));
  };

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
  });
  const SelectedMatch = useSelector((state) => state?.topmatches?.data?.data);
  const resultArray = SelectedMatch?.availableOddsMap
    ? Object.values(SelectedMatch.availableOddsMap).map(({ ...rest }) => rest)
    : [];

  const options =
    resultArray?.length &&
    resultArray?.map((val) => {
      return { label: val?.name, value: val?.id };
    });

  const hasValues = (datal, state) => {
    if (datal?.hasOwnProperty(state)) {
      const results = datal[state];

      return Array.isArray(results) ? results : [results];
    }
  };

  const watching = watch();

  const CapHead = (id) => {
    const res = resultArray?.filter((val) => val.id === id);

    return res[0]?.captions;
  };

  const createArrayFromDigit = (digit) => {
    return Array.from({ length: digit }, (_, index) => index + 1);
  };

  return (
    <div className={` md:w-full max-h-screen  bg-gray-200`}>
      <div className="sticky top-0 w-full py-2 z-20 bg-gray-200 ">
        {/* Add overflow-auto and force minimum width larger than the parent */}
        <div className="flex gap-2 w-full overflow-x-auto px-3 scrollbar-hide  bg-gray-200">
          <div className=" flex w-full justify-end gap-2 min-w-max  bg-gray-200">
            {/* Ensure children expand beyond screen */}
            {createArrayFromDigit(SelectedMatch?.dropDownCount).map(
              (val, index) => {
                return (
                  <div
                    className={`${showDetail ? "hidden" : ""}  bg-gray-200`}
                    key={index}
                  >
                    <Controller
                      name={`dropdown_${index}`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value || null}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption)
                          }
                          options={options || []}
                          menuPortalTarget={document.body} // Renders dropdown in the body element
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#fff",
                              height: "36px",
                              width: "180px",
                              borderRadius: "6px",
                              borderColor: "#757575",
                              color: "#757575",
                              boxShadow: "none",
                              "&:hover": {
                                borderColor: "#757575",
                              },
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown shows above everything
                            indicatorSeparator: () => ({ display: "none" }),
                          }}
                        />
                      )}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* Card List */}
      {SelectedMatch?.matches.map((item, index) => (
        <div key={index} className="bg-white w-full">
          {/* Sticky Card Header */}
          <div className=" grid grid-cols-8 lg:grid-cols-12 py-2 px-2  top-[55px] bg-gray-200 border-b border-gray-300  pb-1 z-10">
            <div
              className={`${
                showDetail
                  ? "text-[10px] font-semibold"
                  : "text-sm col-span-4 font-semibold"
              } lg:col-span-5 flex gap-1`}
            >
              {item?.tournamentName}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                fill="#676767"
              >
                <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
              </svg>
              {/* <div className="col-span-5"></div> */}

              {/* <div className="col-span-7 grid grid-cols-12"> */}
              {/* col-span-3 */}

              {/* col-span-5 */}

              {/* </div> */}
            </div>

            {watching &&
              [watching]?.map((val) => {
                const dropdowns = [
                  val?.dropdown_0,
                  val?.dropdown_1,
                  val?.dropdown_2,
                  val?.dropdown_3,
                  val?.dropdown_4,
                ];
                const selectedDropdown = dropdowns.filter(
                  (drop) => drop?.value
                );

                if (!selectedDropdown?.length) return null;

                return (
                  <div
                    key={val.id} // Unique identifier for the div
                    className={`${
                      showDetail ? "col-span-5 px-4" : "col-span-3"
                    } flex justify-between px-6 font-semibold text-xs text-gray-400`}
                  >
                    {selectedDropdown.map((drop) => {
                      const marketId = hasValues(
                        SelectedMatch?.odds,
                        `${item?.id}_${drop.value}` // Use the current dropdown value
                      )?.pop()?.market_id;

                      const marketValues = CapHead(marketId);

                      return (
                        <div
                          key={drop.value} // Unique identifier for the div
                          className={`${
                            showDetail ? "col-span-5 px-4" : "col-span-3"
                          } flex justify-between px-6 font-semibold text-xs text-gray-400`}
                        >
                          <div className="flex ">
                            {/* <span>{drop.value}</span> */}
                            {marketValues?.map((value) => (
                              <span key={value} className="text-gray-600">
                                {value}
                              </span>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            <div
              className={`${
                showDetail ? "col-span-2 px-4" : "col-span-3"
              } grid grid-cols-3 justify-between   px-6 font-semibold text-xs text-gray-400 `}
            >
              <span className=" flex justify-center">1</span>
              <span className=" flex justify-center">X</span>
              <span className=" flex justify-center">2</span>
            </div>
            <div className="gap-5" />
            {/* col-span-3 */}
            <div
              className={`${
                showDetail ? "col-span-2 px-4" : "col-span-3"
              } grid grid-cols-3 justify-between    font-semibold text-xs text-gray-400 `}
            >
              <span className=" flex justify-center">Over</span>
              <span className=" flex justify-center">Under</span>
            </div>
            <div className="col-span-1"></div>
          </div>

          {/* Card Content */}
          <div className="w-full border-b border-gray-300">
            <div
              className="grid grid-cols-8 lg:grid-cols-12   cursor-pointer hover:bg-gray-100 items-center gap-2 w-full px-4 py-2 border-b border-gray-300"
              onClick={() => {}}
            >
              {/* Match Time */}
              <div
                className={`${
                  showDetail ? "col-span-2" : "col-span-1"
                } flex flex-col  text-gray-500 overflow-hidden text-[10px]`}
              >
                <span className="w-full whitespace-nowrap truncate">
                  {moment(item.match_time * 1000).format("dddd")}
                </span>
                <span className="whitespace-nowrap truncate">
                  {moment(item.match_time * 1000).format("hh:mm")}
                </span>
              </div>

              {/* Team Names */}
              <div
                className={`${
                  showDetail ? "col-span-4" : "col-span-3 lg:col-span-4"
                } text-xs font-semibold text-black`}
              >
                <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
                  <img src={shirt1} alt="" width={20} height={20} />
                  <span>{item?.away_team_name}</span>
                </div>
                <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
                  <img src={shirt2} alt="" width={20} height={20} />
                  <span>{item.home_team_name}</span>
                </div>
              </div>
              <div className=" col-span-3 grid grid-cols-3 gap-1 w-full justify-between">
                {oddsList.map((i) => {
                  return (
                    <div
                      key={i}
                      className="flex hover:bg-blue-200 justify-center items-center  border border-gray-300 rounded-md bg-gray-200 py-2     cursor-pointer"
                      onClick={() =>
                        handleOdd({
                          matchId: item.matchId,
                          home_team_name: item.home_team_name,
                          away_team_name: item.away_team_name,
                          oddsId: i.id,
                        })
                      }
                    >
                      <span className="text-gray-700 ">{i}</span>
                    </div>
                  );
                })}
              </div>
              <div className="gap-5"></div>
              <div className=" col-span-2 grid grid-cols-2 gap-1 w-full justify-between">
                {oddsList2.map((i) => {
                  return (
                    <div
                      key={i}
                      className="flex hover:bg-blue-200 justify-center items-center  border border-gray-300 rounded-md bg-gray-200 py-2     cursor-pointer"
                      onClick={() => handleOdd(item)}
                    >
                      <span className="text-gray-700 ">{i}</span>
                    </div>
                  );
                })}
              </div>
              <div
                className="col-span-1 grid items-center"
                onClick={() => dispatch(showOddDetail())}
              >
                <div className="flex items-center justify-end">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16px"
                      viewBox="0 -960 960 960"
                      width="16px"
                      fill="#B7B7B7"
                    >
                      <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
                    </svg>
                  </span>
                  <span className="text-xs">
                    {SelectedMatch?.oddCountsByMatchMap &&
                      hasValues(SelectedMatch?.oddCountsByMatchMap, item.id)}
                  </span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="14px"
                      viewBox="0 -960 960 960"
                      width="14px"
                      fill="#CCCCCC"
                    >
                      <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                    </svg>
                  </span>
                </div>
              </div>

              {/* Odds Display */}
              <div
                className={`${
                  showDetail
                    ? "col-span-4 text-[10px]"
                    : "col-span-6 text-[10px] md:text-base"
                } text-center grid grid-cols-3 gap-1 items-center`}
              >
                {watching &&
                  [watching]?.map((val, ina) => {
                    const dropdowns = [
                      val?.dropdown_0,
                      val?.dropdown_1,
                      val?.dropdown_2,
                      val?.dropdown_3,
                      val?.dropdown_4,
                    ];
                    const selectedDropdown = dropdowns.filter(
                      (drop) => drop?.value
                    );

                    if (!selectedDropdown?.length) return null;

                    return (
                      <div
                        key={ina} // Unique identifier for the div
                        className={`${
                          showDetail ? "col-span-5 px-4" : "col-span-3"
                        } flex justify-between px-6 font-semibold text-xs text-gray-400`}
                      >
                        {selectedDropdown.map((drop, inde) => {
                          const marketId = hasValues(
                            SelectedMatch?.odds,
                            `${item?.id}_${drop?.value}` // Use the current dropdown value
                          );
                          console.log("marketId" + marketId);
                          const marketValues = SelectedMatch?.odds[marketId];
                          console.log("market Values", marketValues);
                          return (
                            <div
                              key={inde} // Unique identifier for the div
                              className={`${
                                showDetail ? "col-span-5 px-4" : "col-span-3"
                              } flex justify-between px-6 font-semibold text-xs text-gray-400`}
                            >
                              <div className="flex">
                                {marketId &&
                                  marketId?.map((value) => (
                                    <div className="border border-gray-300 rounded-md bg-gray-200 py-1 px-2 cursor-pointer">
                                      {value.value}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>

              {/* {watching &&
              [watching]?.map((val) => {
                const dropdowns = [
                  val?.dropdown_0,
                  val?.dropdown_1,
                  val?.dropdown_2,
                  val?.dropdown_3,
                  val?.dropdown_4,
                ];
                const selectedDropdown = dropdowns.find((drop) => drop?.value);

                if (!selectedDropdown) return null;

                const marketId = hasValues(
                  SelectedMatch?.odds,
                  `${item?.id}_${selectedDropdown?.value}`
                )?.pop()?.market_id;
                const marketValues = CapHead(marketId);
                return (
                  <div
                    key={selectedDropdown.value} // Unique key for the div
                    className={`${
                      showDetail ? "col-span-5 px-4" : "col-span-3"
                    } flex justify-between px-6 font-semibold text-xs text-gray-400`}
                  >
                    {marketValues?.map((value) => (
                      <span key={value}>{value}</span>
                    ))}
                  </div>
                );
              })} */}

              {/* Additional Stats */}
              {/* <div
                className={`${
                  showDetail
                    ? "col-span-1 hidden"
                    : "col-span-1 hidden lg:block"
                }`}
              >
                <span className="flex justify-end">2,3</span>
              </div> */}

              {/* <div
                className={`${
                  showDetail
                    ? "col-span-2 hidden"
                    : "col-span-2 hidden lg:block"
                }`}
              >
                <div className="flex gap-1">
                  {hasValues(
                    SelectedMatch?.odds,
                    `${item?.id}_${watching?.dropdown_1?.value}`
                  ) &&
                    hasValues(
                      SelectedMatch?.odds,
                      `${item?.id}_${watching?.dropdown_1?.value}`
                    ).map((val, indes) => (
                      <div
                        key={indes}
                        className="flex justify-between items-center"
                      >
                        <div className="border border-gray-300 rounded-md bg-gray-200 py-1 px-2 cursor-pointer">
                          {val?.value}
                        </div>
                      </div>
                    ))}
                </div>
              </div> */}

              {/* Stats and Details */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
