import { useState } from "react";
import CustomSwitch from "../../../components/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  initializeWebSocket,
  LiveMatchesApi,
} from "../../../store/actions/live_matches";
import { sportsApi } from "../../../store/actions/sportsNav";
import ComboboxGroup from "../../../components/ComboboxGroup";
import { Matches } from "../../../components/MatchesRow";
import { OddsNameGroup } from "../../../components/OddsNameGroup";

export default function LiveBetting() {
  const dispatch = useDispatch();
  const [betTab, setBetTab] = useState("-1");
  const data = useSelector((state) => state?.livematches?.data?.data);
  const listOfSports = [
    ...Object.entries(data?.availableSports || {}),
    ["-1", "AllBalls"],
  ];

  useEffect(() => {
    dispatch(
      LiveMatchesApi({
        pageNumber: 0,
        pageSize: 50,
      })
    );
    dispatch(sportsApi());
    dispatch(initializeWebSocket());
  }, [dispatch]);

  useEffect(() => {
    console.log("Data change", data);
  }, [data]);

  return (
    <div className="flex w-full h-[95vh]">
      <div
        className={`w-full h-full bg-gray-200 px-5  overflow-auto scrollbar-hide`}
      >
        <div className="flex items-center w-full overflow-x-auto gap-1 mt-12 bg-gray-200 ">
          {listOfSports.map((entries) => {
            return (
              <div
                key={entries[0]}
                onClick={() => {
                  setBetTab(entries[0]);
                }}
                className={`flex-shrink-0 cursor-pointer flex flex-col py-2 justify-between  my-1 rounded-sm items-end  w-20 h-20 ${
                  betTab === entries[0]
                    ? "bg-white border-b-2 border-b-red-400"
                    : "bg-gray-200 shadow-sm shadow-gray-400"
                }`}
              >
                <div className="flex justify-end px-2">
                  <div></div>
                  <img
                    src={
                      "http://38.180.122.61/images/sports/" +
                      (entries[1] + "").toLowerCase().replace(" ", "-") +
                      ".png"
                    }
                    alt={entries[1]}
                    width={42}
                    height={42}
                  />
                  <span className="text-xs text-gray-500 font-extralight">
                    {""}
                    55
                  </span>
                </div>
                <span className="flex w-full text-xs font-semibold justify-center">
                  {entries[1]}
                </span>
              </div>
            );
          })}
        </div>
        <div className={`flex items-center w-full gap-4  py-4`}>
          <span>Sort by:</span>
          <CustomSwitch />
        </div>
        <div className="flex w-full ">
          <CardList
            showDetail={false}
            hndleDetail={() => {}}
            betTab={betTab}
            imgSource={
              listOfSports.find(([key]) => key === String(betTab))
                ? listOfSports.find(([key]) => key === String(betTab))[1]
                : "AllBalls"
            }
          />
        </div>
      </div>
    </div>
  );
}

const CardList = ({ showDetail, betTab, imgSource }) => {
  const [selectedOddsIds, setSelectedOddsIds] = useState([]);
  const data = useSelector((state) => state?.livematches?.data?.data);

  const handleComboboxValuesChange = (values) => {
    const filteredValues = values.filter(
      (value) => value !== null && value !== ""
    );
    setSelectedOddsIds(filteredValues);
  };
  const options = Object.values(data?.availableOddsMap ?? []).map(
    (element) => ({
      value: element.id,
      label: element.name,
    })
  );
  return (
    <div className={` md:w-full shadow-xl `}>
      {/* Sticky Hello Section */}
      <div className="sticky flex justify-between top-0 gap-3 pr-5 bg-gray-100  pt-6 z-20 px-3">
        <div className="flex items-center gap-2">
          <img
            src={
              "http://38.180.122.61/images/sports/" +
              imgSource.toLowerCase().replace(" ", "-") +
              ".png"
            }
            alt={imgSource}
            width={35}
            height={35}
          />
          <span className="font-semibold">{imgSource}</span>
        </div>
        <div className="flex gap-4">
          <div className={`${showDetail ? "hidden" : ""}`}>
            <ComboboxGroup
              options={options}
              count={5}
              hasEmptyLabel={false}
              onValuesChange={handleComboboxValuesChange}
            />
          </div>
        </div>
      </div>

      {/* Card List */}
      <div className="bg-white w-full">
        <OddsNameGroup
          availableOdds={data?.availableOddsMap ?? []}
          selectedOddsIds={selectedOddsIds}
        />

        <div className=" w-full border-b border-gray-300 ">
          {data?.matches
            ?.filter((item) => item.sport_id + "" === betTab || betTab === "-1")
            .map((item) => {
              return (
                <Matches
                  key={item.id}
                  item={item}
                  oddList={data?.odds}
                  selectedOddsIds={selectedOddsIds}
                  availableOdds={data?.availableOddsMap}
                  handleDetail={(e) => {
                    console.log("Handle Detail Basıldı");
                  }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
