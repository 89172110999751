import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import routes from "./routes";
import "./App.css";
import DefaultLayout from "./app/Layout/DefaultLayout";
import MainLayout from "./app/Layout/MainLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import LiveBattingLayout from "./app/Layout/LiveBattingLayout";
import BetGuideLayout from "./app/Layout/BetGuideLayout";
import { PersistGate } from "redux-persist/integration/react";
const useAuth = () => {
  const authToken = localStorage.getItem("authToken");
  return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
};

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <DefaultLayout>
      <Element {...rest} />
    </DefaultLayout>
  ) : (
    <Navigate to="/login" />
  );
};

const PublicRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  return isAuthenticated && location.pathname === "/login" ? (
    <Navigate to="/dashboard" />
  ) : location.pathname === "/login" || location.pathname === "/register" ? (
    <DefaultLayout>
      <Element {...rest} />
    </DefaultLayout>
  ) : location.pathname === "/live-betting" ? (
    <LiveBattingLayout>
      <Element {...rest} />
    </LiveBattingLayout>
  ) : location.pathname === "/bet-guide" ? (
    <BetGuideLayout>
      <Element {...rest} />
    </BetGuideLayout>
  ) : (
    <MainLayout>
      <Element {...rest} />
    </MainLayout>
  );
};

const RouteWrapper = ({ element: Element, isProtected, ...rest }) => {
  if (isProtected) {
    return <ProtectedRoute element={Element} {...rest} />;
  } else {
    return <PublicRoute element={Element} {...rest} />;
  }
};
const queryClient = new QueryClient();
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <RouteWrapper
                      element={route.element}
                      isProtected={route.isProtected}
                    />
                  }
                />
              ))}
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
