import { shirt1, shirt2 } from "../assets/images";
import { OddButtons } from "./CustomOddButtons";

export const Matches = ({
  item,
  oddList,
  selectedOddsIds,
  availableOdds,
  handleDetail,
}) => {
  return (
    <div
      key={item.id}
      className="grid grid-cols-8 lg:grid-cols-12 cursor-pointer hover:bg-gray-100 items-center  w-full px-3 py-2 border-b border-gray-300"
      onClick={handleDetail}
    >
      <div className="col-span-5 grid grid-cols-12">
        <div
          className={
            "col-span-2 grid grid-cols-1 text-xs text-gray-500 truncate"
          }
        >
          <span className="mt-4">{item.date}</span>
          <span>{item.time}</span>
        </div>
        <div className="flex justify-between col-span-10 ">
          <div className="flex flex-col">
            <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
              <img src={shirt2} alt="" width={20} height={20} />
              <span>{item.home_team_name}</span>
            </div>
            <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
              <img src={shirt1} alt="" width={20} height={20} />
              <span>{item.away_team_name}</span>
            </div>
          </div>
          <span className="flex justify-evenly">
            <div className="flex w-full flex-col justify-evenly">
              <div className="flex gap-2 justify-evenly">
                <span>{item.home_team_score}</span>
                <span className="blinker">{item.home_team_score}</span>
              </div>
              <div className="flex gap-2 justify-evenly">
                <span>{item.away_team_score}</span>
                <span className="blinker">{item.away_team_score}</span>
              </div>
            </div>
          </span>

          <style jsx>{`
            .blinker {
              animation: blinker 1.8s linear 4;
              font-family: sans-serif;
            }

            @keyframes blinker {
              0%,
              100% {
                opacity: 1;
              }
              50% {
                opacity: 0;
              }
            }
          `}</style>
        </div>
      </div>

      <div className="col-span-7 grid grid-cols-12">
        <div
          className={
            "col-span-11 flex justify-between px-6 font-semibold text-xs text-gray-400"
          }
        >
          <div className="col-span-10 flex gap-1 w-full justify-between">
            <OddButtons
              key={item.id}
              item={item}
              oddList={oddList}
              selectedOddsIds={selectedOddsIds}
              availableOdds={availableOdds}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
