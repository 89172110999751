
import { clsx } from "clsx";


function Button({ children, variant = "primary", ...props }) {
  const { className, disabled } = props;
  const commonStyle =
    "text-sm text-center md:text-base disabled:bg-gray-600 font-bold  leading-[20px] min-w-[94px] md:min-w-[94px]  md:py-[6px] px-[6px] md:px-[10px] min-h-[36px] md:max-h-[57px] rounded-sm";
  const RoundedStyle =
    "text-sm xl:text-base disabled:bg-gray-600 font-bold leading-[20px] min-w-[103] xl:min-w-[120px] py-[6px] xl:py-[12px] px-[12px] xl:px-[20px] h-[37px] xl:h-[47px] rounded-full";
  const OutLineStyle =
    "text-sm md:text-base disabled:bg-gray-600 font-bold text-white border border-solid border-white bg-transparent leading-[22px] min-w-[103] md:min-w-[139px] md:py-[12px] px-[10px] md:px-[20px] h-[37px] md:h-[47px] rounded-lg";
  switch (variant) {
    case "primary":
      return (
        <button
        {...props}
          className={clsx(
            ``,
            commonStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
      
        >
          {children}
        </button>
      );
    case "primaryOutLine":
      return (
        <button
          {...props}
          type="button"
          className={clsx(
            `hover:bg-blue-700 hover:text-white`,
            OutLineStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>
      );
    case "primaryRounded":
      return (
        <button
          {...props}
          type="button"
          className={clsx(
            `bg-[#0063F7] text-white  hover:bg-blue-700`,
            RoundedStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>
      );
    case "outlinePrimaryRounded":
      return (
        <button
          {...props}
          type="button"
          className={clsx(
            `bg-white outline-1 outline outline-primary-500 text-primary-500 `,
            RoundedStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>
      );
    case "secondary":
      return (
        <button
          {...props}
          type="button"
          className={clsx(
            `bg-[#616161] hover:bg-secondary-700`,
            commonStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>
      );
    case "danger":
      return (
        <button
          {...props}
          type="button"
          className={clsx(
            `bg-red-600 hover:bg-red-700`,
            commonStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>
      );

    case "simple":
      return (
        <button
          {...props}

          className={clsx(
            `bg-white text-black`,

            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>

      );
    default:
      return (
        <button
          {...props}
          type="button"
          className={clsx(
            `bg-blue-500 hover:bg-blue-700`,
            commonStyle,
            className,
            disabled && "opacity-50 cursor-not-allowed"
          )}
        >
          {children}
        </button>
      );
  }
}

export { Button };
