import { combineReducers } from "redux";
import authReducer from "./authReducer";
import authRegistrationReducer from "./authRegistrationReducer";
import { MatchReducer } from "./ApiReducers";
import { ApiSportsReducer } from "./apisports";
import sidebarReducer from "./sidebarReducer";
import homeReducer from "./homeReducers";
import { TopMatchReducer } from "./topmatches";
import { LiveMatchReducer } from "./liveMatchesReducer";
import { TopWinnerReducer } from "./topWinnerReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  authreg: authRegistrationReducer,
  apireducer: MatchReducer,
  apisport: ApiSportsReducer,
  sidebar: sidebarReducer,
  homereducer: homeReducer,
  topmatches: TopMatchReducer,
  livematches: LiveMatchReducer,
  topWinner: TopWinnerReducer,
});

export default rootReducer;
