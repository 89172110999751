import React, {  useState,useEffect } from "react";
import { DetailCard } from "../../../components/LandingPage/DetailCard";
import { MainPage } from "../../../components/LandingPage/main_page";
import { TopEvents } from "../../../components/LandingPage/Top_Evetns";
import { TopCombis } from "../../../components/LandingPage/Top_Combis";
import { TopGames } from "../../../components/LandingPage/Today_Games";
import { GermonFootball } from "../../../components/LandingPage/Germon_Football";
import { EuropeanFootball } from "../../../components/LandingPage/European_Football";

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MatchesApi } from "../../../store/actions/matchesApi";
import { sportsApi } from "../../../store/actions/sportsNav";
import axios from "axios";
import moment from "moment";
import { shirt1, shirt2 } from "../../../assets/images";

export default function HomePage() {
  const activeMenu = useSelector((state) => state.sidebar.selectedItem); 
    const [showDetail, setDetail] = useState(false);
    const [betTab, setBet] = useState();
    const [datas, setData] = useState([]);
    const hndleDetail = () => {
      setDetail(!showDetail);
    };


    // const showDetail = useSelector((state) => state.homereducer.showDetail); 


    const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const error = useSelector((state) => state.auth.error);
    // const {isAuthenticated} = useSelector((state) => state.auth);
    // const [loading, setloading] = useState(false);
    // const responces = await dispatch(sportsApi())
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            "http://38.180.122.61:3000/api/mapping/sports"
          );
          setData(response.data);
          await dispatch(
            MatchesApi({
              pageNumber: 0,
              pageSize: 5,
              sport_id: 1,
            })
          );
       
          if(datas){
            setBet(datas[0].name)
          }
        } catch (error) {
          console.log(error);
        }
      };
      
      fetchData();
    }, []);

return <>
   <div className="flex w-full h-full">
    {/* <div>{activeMenu}</div> */}
    <div  className={`w-full  h-[95vh] bg-gray-200 px-5  overflow-auto scrollbar-hide`}>
  {/* MainSection */}
  {
      activeMenu==undefined && <MainPage  showDetail={showDetail} hndleDetail={hndleDetail}/>
    }
    {/* Top Events  */}
    {
      activeMenu=='top-events'&& <TopEvents datas={datas}/>
    }
    {/* TopCombis */}
    {
      activeMenu=='top-combis' && <TopCombis/>
    }
    {/* TopGames */}
    {
      activeMenu=='todays-matches'&& <TopGames/>
    }
    {/* GermonFootball */}
    {
      activeMenu =='german-football' && <GermonFootball/>
    }
    {/* EuropeanFootball */}
    {
      activeMenu =='european-football' && <EuropeanFootball/>
    }
    
    </div>
 
    {
      showDetail&&<DetailCard showDetail={showDetail} hndleDetail={hndleDetail} />
    }
   </div>


</>
}



const CardList = ({ showDetail, hndleDetail, betTab }) => {
  const cards = [
    {
      id: 1,
      header: "Spain / La Liga",
      content: "This is the content for card 1 in List One",
    },
    {
      id: 2,
      header: "England / Premier League",
      content: "This is the content for card 2 in List One",
    },
    {
      id: 3,
      header: "Germany / Bundesliga",
      content: "This is the content for card 3 in List One",
    },
    {
      id: 4,
      header: "Italy / Serie A",
      content: "This is the content for card 4 in List One",
    },
    {
      id: 5,
      header: "France / Ligue 1",
      content: "This is the content for card 5 in List One",
    },
  ];
  const cards2 = [
    {
      id: 6,
      header: "USA / MLS",
      content: "This is the content for card 1 in List Two",
    },
    {
      id: 7,
      header: "Brazil / Serie A",
      content: "This is the content for card 2 in List Two",
    },
    {
      id: 8,
      header: "Argentina / Primera Division",
      content: "This is the content for card 3 in List Two",
    },
    {
      id: 9,
      header: "Mexico / Liga MX",
      content: "This is the content for card 4 in List Two",
    },
    {
      id: 10,
      header: "Portugal / Primeira Liga",
      content: "This is the content for card 5 in List Two",
    },
  ];
  const matches = useSelector((values)=>  values?.apireducer?.data?.data ? values?.apireducer?.data?.data : [])

 
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(),
  });

  const GetDAte = (currentDate) => {
    const date12 = new Date(currentDate * 1000); // Convert seconds to milliseconds

  // Format the date to a readable string
  const formattedDate = date12.toLocaleString(); // You can customize this

  return new Date(formattedDate)
  }
  

  return (
    <div className={` md:w-full min-h-screen`}>
      {/* Sticky Hello Section */}
      <div className="sticky flex justify-end top-0 gap-3 pr-5 bg-gray-200  pt-6 z-20 ">
        <div className={`${showDetail ? "hidden" : ""}`}>
          <Controller
            name="betone"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: "Tip", label: "Tip" },
                  { value: "About/Under", label: "About/Under" },
                ]}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    height: "36px",
                    width: "140px",
                    borderRadius: "6px",
                    borderColor: "#757575",
                    color: "#757575",
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "#757575",
                    },
                  }),
                  indicatorSeparator: () => ({ display: "none" }),
                }}
              />
            )}
          />

          {/* <SelectComponent
            options={[
              { value: "Tip", label: "Tip" },
              { value: "About/Under", label: "About/Under" },
            ]}
            onChange={(value) => {
              console.log(value);
            }}
            height="36ox"
            backgroundColor="#fff"
            width="140px"
            selectedOptions={[{ value: "Tip", label: "Tip" }]}
          /> */}
        </div>

        <div>
          <Controller
            name="bettwo"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: "Tip", label: "Tip" },
                  { value: "About/Under", label: "About/Under" },
                ]}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    height: "36px",
                    width: "140px",
                    borderRadius: "6px",
                    borderColor: "#757575",
                    color: "#757575",
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "#757575",
                    },
                  }),
                  indicatorSeparator: () => ({ display: "none" }),
                }}
              />
            )}
          />
        </div>
      </div>

      {/* Card List */}
   
        <div className="bg-white w-full">
          {/* Sticky Card Header */}
          <div
            className={` sticky grid grid-cols-8 lg:grid-cols-12 top-[60px] bg-gray-200 border-b border-gray-300 pt-5 pb-1 z-10`}
          >
            <div
              className={`${
                showDetail
                  ? "text-[10px] font-semibold"
                  : "text-sm col-span-4 font-semibold "
              } lg:col-span-5   flex gap-1  `}
            >
              ""

              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                fill="#676767"
              >
                <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
              </svg>
            </div>

            <div
              className={`${
                showDetail ? "col-span-5 px-4" : "col-span-3"
              } flex justify-between px-6 font-semibold text-xs text-gray-400 `}
            >
              <span>1</span>
              <span>X</span>
              <span>2</span>
            </div>
            <div className="col-span-1 hidden lg:block"></div>
            <div
              className={`col-span-2   ${
                showDetail ? "hidden" : "hidden lg:block"
              }`}
            >
              <div className="flex justify-between pl-4 pr-9  font-semibold text-xs text-gray-400">
                <span>X</span>
                <span>2</span>
              </div>
            </div>
            <div className="col-span-1 "></div>
          </div>

          {/* Card Content */}
          <div className=" w-full border-b border-gray-300 ">
            {matches?.matches?.length > 0 && matches?.matches.map((item) => {
              return (
                <div
                  key={item.id}
                  className="grid grid-cols-8 lg:grid-cols-12 cursor-pointer hover:bg-gray-100 items-center gap-2 w-full px-4 py-2 border-b border-gray-300"
                  // onClick={hndleDetail}
                >
                  {/* 1 col  */}
                  <div
                    className={`${
                      showDetail ? "col-span-2" : "col-span-1"
                    } grid grid-cols-1 text-xs text-gray-500 truncate`}
                  >
                    <span>{moment(GetDAte(item?.match_time)).format("DD/MM/YY")}</span>
                    <span>{moment(GetDAte(item?.match_time)).format("hh:mm A")}</span>
                  </div>
                  {/* 4 col  */}
                  <div
                    className={`${
                      showDetail ? "col-span-4 " : " col-span-3 lg:col-span-4"
                    }    text-xs font-semibold text-black`}
                  >
                 
                    <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
                      <img src={shirt1} alt="" width={20} height={20} />
                      <span>{item.away_team_name}</span>
                    </div>
                    <div className="grid grid-cols-[auto,1fr] items-center gap-2 truncate">
                      <img src={shirt2} alt="" width={20} height={20} />
                      <span>{item.home_team_name}</span>
                    </div>
                  </div>
                  {/* 3 col   */}
                  <div
                    className={`${
                      showDetail
                        ? "col-span-4 text-[10px] "
                        : "col-span-3 text-[10px] md:text-base "
                    } text-center grid grid-cols-3 gap-1 items-center `}
                  >
                    {/* {item.odds.map((i) => {
                      return (
                        <div
                          key={i}
                          className="border border-gray-300 rounded-md bg-gray-200 py-1    cursor-pointer"
                        >
                          {`${i}`}
                        </div>
                      );
                    })} */}
                  </div>
                  {/* 4 col  */}
                  <div
                    className={`${
                      showDetail
                        ? "col-span-1  hidden"
                        : "col-span-1 hidden lg:block"
                    } `}
                  >
                    <span className="flex justify-end">2,3</span>
                  </div>
                  <div
                    className={`${
                      showDetail
                        ? "col-span-2  hidden"
                        : "col-span-2 hidden lg:block "
                    } `}
                  >
                    <div className="flex gap-1  ">
                      {[1, 2].map((i) => {
                        return (
                          <div
                            key={i}
                            className="border border-gray-300 rounded-md bg-gray-200 py-1 px-2  cursor-pointer"
                          >
                            {`${i},43`}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-span-1 grid items-center">
                    <div className="flex  items-center justify-end">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16px"
                          viewBox="0 -960 960 960"
                          width="16px"
                          fill="#B7B7B7"
                        >
                          <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
                        </svg>
                      </span>
                      <span className="text-xs">{item.stats}</span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="14px"
                          viewBox="0 -960 960 960"
                          width="14px"
                          fill="#CCCCCC"
                        >
                          <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
     
    </div>
  );
};
