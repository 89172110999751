
// import { useState } from "react";
import { logo } from '../../assets/images'
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/authActions';
function Header() {
  const navigate = useNavigate();
  const useAuth = () => {
    const authToken = localStorage.getItem('authToken');
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');  
  };
  const { isAuthenticated } = useAuth();
  return (
   <>
     {/* Hidden on big screens */}
    <div className="hidden sm:block h-full">
      <nav className="flex items-center justify-between w-full px-4 bg-primary-600 shadow bg-[#c8102e] h-14 fixed z-50 top-0 left-0  max-w-[1920px]">
        <div className="justify-start w-full px-4 items-center flex md:px-8 gap-12 lg:gap-24">
        <Link to="/" className='logo'>
            <img src={logo} alt="logo" width={90} height={35} />
          </Link>
          <ul className='flex text-white font-normal text-xs lg:text-sm gap-3 md:gap-6'>
            <li><Link to={"/"}>Sports Betting</Link></li>
            <li><Link to={"/live-betting"}>Live Betting</Link></li>
            <li><Link to={"/abx"}>Promotions</Link></li>
          </ul>
        </div>
        <div>
           <ul className='flex gap-2'>
           {isAuthenticated ? <li> <Button  onClick={handleLogout} variant="primary" className='bg-white font-extralight text-red-800 hover:text-red-700 hover:bg-gray-200'>LogOut</Button></li> :  <><li > <Link to={'/register'}> <Button variant="primary" className='bg-black bg-opacity-40 font-extralight text-white text-opacity-80 hover:text-gray-300 hover:bg-opacity-20'>Register</Button></Link></li>
            <li> <Link to={'/login'}><Button variant="primary" className='bg-white font-extralight text-red-800 hover:text-red-700 hover:bg-gray-200'>Login</Button></Link></li></>
           }
           
           </ul>
          </div>
      </nav>
  
    </div>
      {/* Hidden on small screens */}
      <header className="block md:hidden">
          <div className="flex justify-between items-center w-full   bg-[#c8102e] h-[56px] px-4">
              <Link to="/" className="logo">
                <img src={logo} alt="logo" width={90} height={35} />
              </Link>
              <Button  variant="primaryOutLine" className='font-extralight '>Login</Button>
          </div>
        </header>
   </>
  );
}

export default Header;
